import { IpisFormBoundaries, IpisFormElement } from "@eljouren/domain";
import { UUID } from "@eljouren/utils";
import ChecklistDecoupledImageGroupQuestion from "../../../checklist-question-types/image-group/ChecklistDecoupledImageGroupQuestion";
import WizardForm from "../../../wizard-form/WizardForm";
import { useWizardForm } from "../../../wizard-form/use-wizard-form";
import InputBuilderLayout from "../InputBuilderLayout";
import { WizardFormInference } from "../common-wizard-fields";

const page = WizardFormInference.inferQuestionArray([
  {
    id: "image-prompts",
    clientSideId: null,
    required: true,
    walkthroughHeading: "Repeater testing",
    walkthroughSubheading: "Repeater testing",
    typeOfQuestion: "repeater",
    type: "question",
    title: "Vad ska användaren ladda upp bilder på?",
    repeaterGroup: {
      name: "Alternativ",
      items: [
        {
          id: "imagePrompt",
          clientSideId: null,
          typeOfQuestion: "text",
          required: true,
          type: "repeater-item",
          placeholder: "Vad användaren ska ladda upp bild på",
          minLength: IpisFormBoundaries.multipleChoice.option.minLength,
          maxLength: IpisFormBoundaries.multipleChoice.option.maxLength,
        },
        {
          id: "required",
          clientSideId: null,
          typeOfQuestion: "yes/no",
          required: true,
          type: "repeater-item",
          renderAs: "toggle",
          title: "Kräv minst en bild av denna typ?",
        },
      ],
    },
  },
]);

const configs = [page];

type FormValues = WizardFormInference.FormValues<typeof configs>;

type SubmitValues = Omit<IpisFormElement.ImageGroupType, "id">;
interface Props {
  className?: string;
  defaultValues?: SubmitValues;
  onSubmit: (data: SubmitValues) => void;
  closeForm: () => void;
}

const ChecklistImageGroupBuilder = (props: Props) => {
  function submitValuesToFormValues(
    values: SubmitValues | undefined
  ): Partial<FormValues> {
    if (!values) {
      return {};
    }

    const repeaterItems = values.imagePrompts.reduce((acc, prompt) => {
      acc[prompt.id] = {
        imagePrompt: {
          value: prompt.promptText,
        },
        required: {
          value: prompt.required,
        },
      };
      return acc;
    }, {} as Record<string, { imagePrompt: { value: string }; required: { value: boolean } }>);

    const partial: Partial<FormValues> = {
      "image-prompts": {
        repeaterItems: repeaterItems,
      },
    };

    return partial;
  }

  const wizardHook = useWizardForm({
    formId: "checklist-text-input-builder",
    configs,
    onSubmit: onSubmit,
    defaultValues: {
      ...submitValuesToFormValues(props.defaultValues),
    },
  });

  const question = buildQuestion(wizardHook.form.watch());

  function buildQuestion(values: FormValues): IpisFormElement.ImageGroupType {
    const repeaterItems = values["image-prompts"]?.repeaterItems ?? {};
    const groups = Object.values(repeaterItems);

    const imagePrompts: IpisFormElement.ImageGroupType["imagePrompts"] = groups
      .filter((group) => !!group.imagePrompt?.value)
      .map((group) => {
        const option: IpisFormElement.ImageGroupType["imagePrompts"][number] = {
          id: UUID.generate().value,
          clientSideId: null,
          promptText: group.imagePrompt?.value?.toString() ?? "",
          required: !!group.required?.value,
        };
        return option;
      });

    return {
      id: "generator",
      clientSideId: null,
      type: "question",
      typeOfQuestion: "image-group",
      imagePrompts,
      required: true,
    };
  }

  const atLeastOneQuestionAnswered = Object.values(
    wizardHook.form.getValues()
  ).some((value) => !!value);

  function onSubmit(data: FormValues) {
    props.onSubmit(buildQuestion(data));
  }

  return (
    <InputBuilderLayout
      closeForm={props.closeForm}
      heading="Skapa fält för att ladda upp bilder"
      description="Placera helst dessa ensam på en sida och samla all bilduppladdning i samma grupp av bilder. Låt sidans titel och beskrivning vara det som beskriver frågan."
      atLeastOneQuestionAnswered={atLeastOneQuestionAnswered}
      Form={<WizardForm hookReturn={wizardHook} />}
      PreviewComponent={
        <ChecklistDecoupledImageGroupQuestion
          question={question}
          form={wizardHook.form}
        />
      }
    />
  );
};

export default ChecklistImageGroupBuilder;
