import { WorkOrder } from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas";
import {
  useMutableQuery,
  UseMutableQueryResult,
  useQueryWrapper,
  UseQueryWrapperResult,
} from "@ipis/client-essentials";
import { useContext } from "react";
import { HandymanGetPreparationFormOutput } from "../../_model/api-clients/preparation-form/preparation-form-api-client-types";
import { GlobalContext } from "../../top-level-contexts";
import { useApiClients } from "../use-api-clients";

type Args =
  | {
      as: "salesTeam";
      workOrder: WorkOrder.CustomerType;
      preparationForm: HandymanGetPreparationFormOutput | undefined;
    }
  | {
      as: "handyman";
      workOrder: WorkOrder.HandymanWithPermissionsType;
      preparationForm: HandymanGetPreparationFormOutput | undefined;
    };

type UseAuthenticatedWorkOrderFilesOutput = {
  workOrderFileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
  relatedToWorkOrderFileRes: UseQueryWrapperResult<IpisFileV2.Type[]>;
};

export function useAuthenticatedWorkOrderFiles(
  props: Args
): UseAuthenticatedWorkOrderFilesOutput {
  const { workOrderFileRepo } = useApiClients();
  const { signInState } = useContext(GlobalContext);
  const workOrderFileRes = useMutableQuery({
    queryKey: [
      "workOrderFiles",
      props.as,
      props.workOrder.orderId,
      signInState.signedInAs,
    ],
    queryFn: () => {
      if (props.as === "handyman") {
        return workOrderFileRepo.getFilesAsHandyman({
          workOrderId: props.workOrder.orderId,
        });
      } else {
        return workOrderFileRepo.getFilesAsStaff();
      }
    },
  });

  const relatedToWorkOrderFileRes = useQueryWrapper({
    queryKey: [
      "relatedToWorkOrderFiles",
      props.as,
      props.workOrder.orderId,
      signInState.signedInAs,
    ],
    queryFn: () => {
      if (props.as === "handyman") {
        return workOrderFileRepo.getRelatedFilesAsHandyman({
          workOrderId: props.workOrder.orderId,
        });
      } else {
        return workOrderFileRepo.getRelatedFilesAsStaff();
      }
    },
  });

  return {
    workOrderFileRes,
    relatedToWorkOrderFileRes,
  };
}
