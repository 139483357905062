import { useEffect } from "react";
import { useApiClients } from "../use-api-clients";
import useQueue from "../use-queue";
import {
  UseMutableQueryResult,
  UseQueryWrapperResult,
} from "@ipis/client-essentials";
import { IpisFileV2 } from "@eljouren/file-schemas";
import { ProcessTaskArgs } from "../../utils/process/ProcessTask";
import { WorkOrder } from "@eljouren/domain";
import { CustomFileInputFile } from "../../components/files/FileInputButton";
import IClientWorkOrderFileRepoV2 from "../../_model/repos/interfaces/IClientWorkOrderFileRepoV2";
import UploadResponseErrorMessageGenerator from "../../_model/helpers/UploadResponseErrorMessageGenerator";
import { useAuthenticatedFilePermissions } from "./use-authenticated-file-permissions";

type Args =
  | {
      workOrder: WorkOrder.HandymanWithPermissionsType;
      as: "handyman";
      workOrderFileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
      relatedToWorkOrderFileRes: UseQueryWrapperResult<IpisFileV2.Type[]>;
    }
  | {
      as: "salesTeam";
      workOrder: WorkOrder.CustomerType;
      workOrderFileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
      relatedToWorkOrderFileRes: UseQueryWrapperResult<IpisFileV2.Type[]>;
    };

type UseAuthenticatedFileHandlingOutput = {
  uploadFiles: (files: CustomFileInputFile[]) => void;
  deleteFile: (file: IpisFileV2.Type) => void;
};

export function useAuthenticatedFileHandling(
  args: Args
): UseAuthenticatedFileHandlingOutput {
  const { workOrderFileRepo } = useApiClients();
  const uploadQueue = useQueue({
    id: "file-upload-queue",
    name: "Filuppladdning",
  });
  const deleteQueue = useQueue({
    id: "file-delete-queue",
    name: "Ta bort filer",
  });

  const filePermissions = useAuthenticatedFilePermissions(args);

  useEffect(() => {
    let unsubscribe: () => void;
    if (uploadQueue.isInProgress) {
      unsubscribe = uploadQueue.process.onFinished(() => {
        args.workOrderFileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (deleteQueue.isInProgress) {
      unsubscribe = deleteQueue.process.onFinished(() => {
        args.workOrderFileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  /* 
    This should use batchUpload, but in the scope of the current changes (refactoring the prechecklist flow) it's not worth
	risking to break the current functionality.
  */
  const uploadFiles = async (files: CustomFileInputFile[]) => {
    const useBatchUpload = false;
    if (useBatchUpload) {
      const task: ProcessTaskArgs<any> = {
        name: "Laddar upp filer",
        func: async () => {
          if (args.as === "handyman") {
            const res = await workOrderFileRepo.uploadAsHandyman({
              workOrderId: args.workOrder.orderId,
              files,
            });
            return res;
          } else {
            const res = await workOrderFileRepo.uploadAsSalesTeam({
              files,
            });
            return res;
          }
        },
        onSuccess: (
          res: Awaited<
            ReturnType<IClientWorkOrderFileRepoV2["uploadAsHandyman"]>
          >
        ) => {
          if (res.status !== "fulfilled") {
            return {
              state: "mixed",
              errorMessages: UploadResponseErrorMessageGenerator.generate(res),
              /* 
                Doesn't make sense to pass this back
              */
              result: res,
            };
          }
        },
      };

      uploadQueue.addTasks(task);
    } else {
      const tasks: ProcessTaskArgs<any>[] = files.map((file) => {
        return {
          name: file.meta.name || file.guid,
          func: async () => {
            if (args.as === "salesTeam") {
              const res = await workOrderFileRepo.uploadAsSalesTeam({
                files: [file],
              });
              return res;
            } else {
              const res = await workOrderFileRepo.uploadAsHandyman({
                workOrderId: args.workOrder.orderId,
                files: [file],
              });
              return res;
            }
          },
          onSuccess: (
            res: Awaited<
              ReturnType<IClientWorkOrderFileRepoV2["uploadAsHandyman"]>
            >
          ) => {
            if (res.status !== "fulfilled") {
              return {
                state: "failed",
                errorMessages:
                  UploadResponseErrorMessageGenerator.generate(res),
                /* 
                  Doesn't make sense to pass this back
                */
                result: res,
              };
            }
          },
        };
      });

      uploadQueue.addTasks(...tasks);
    }
  };

  const deleteFile = async (file: IpisFileV2.Type) => {
    if (!filePermissions.forFile(file).canDelete) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Något gick fel",
        prompt: "Du har inte rättigheter att ta bort filen",
        error: null,
      });
      return;
    }
    const tasks: ProcessTaskArgs<any> = {
      name: file.name,
      func: async () => {
        const res = await workOrderFileRepo.deleteFileAsHandyman({
          file,
          workOrderId: args.workOrder.orderId,
        });
        return res;
      },
      onError: (er) => {
        return {
          errorMessage: "Det gick inte att ta bort filen",
        };
      },
    };
    deleteQueue.addTasks(tasks);
  };

  return {
    uploadFiles,
    deleteFile,
  };
}
