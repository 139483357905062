import { WorkOrder } from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { createContext } from "react";
import { HandymanGetPreparationFormOutput } from "../../../_model/api-clients/preparation-form/preparation-form-api-client-types";
import { UseMutableQueryResult } from "../../../hooks/use-mutatable-query";
import { UseQueryWrapperResult } from "../../../hooks/use-query-wrapper";
import { useAuthenticatedFileHandling } from "../../../hooks/work-order-files/use-authenticated-file-handling";
import { useAuthenticatedFilePermissions } from "../../../hooks/work-order-files/use-authenticated-file-permissions";
import { useAuthenticatedWorkOrderFiles } from "../../../hooks/work-order-files/use-authenticated-work-order-files";
import { useFileFiltering } from "../../../hooks/work-order-files/use-file-filtering";
import { useFileSorting } from "../../../hooks/work-order-files/use-file-sorting";
import { FilePermissions } from "../../../types/file-permissions";
import { CustomFileInputFile } from "../../files/FileInputButton";

export type THandymanFileFilters = {
  workOrderFiles: boolean;
  serviceContractFiles: boolean;
  workOrderLineItemFiles: boolean;
  opportunityLineItemFiles: boolean;
  productPackageFiles: boolean;
  preparationFormFiles: boolean;
};

export const HandymanWorkOrderFileContext = createContext<{
  workOrderFileRes: UseMutableQueryResult<IpisFileV2.Type[]>;
  relatedToWorkOrderFileRes: UseQueryWrapperResult<IpisFileV2.Type[]>;
  workOrderImages: IpisFileV2.Type[] | undefined;
  workOrderDocuments: IpisFileV2.Type[] | undefined;
  workOrderLineItemImages: IpisFileV2.Type[] | undefined;
  workOrderLineItemDocuments: IpisFileV2.Type[] | undefined;
  opportunityLineItemImages: IpisFileV2.Type[] | undefined;
  opportunityLineItemDocuments: IpisFileV2.Type[] | undefined;
  productPackageImages: IpisFileV2.Type[] | undefined;
  productPackageDocuments: IpisFileV2.Type[] | undefined;
  serviceContractImages: IpisFileV2.Type[] | undefined;
  serviceContractDocuments: IpisFileV2.Type[] | undefined;
  preparationFormImages: IpisFileV2.Type[] | undefined;
  defaultFilterValues: THandymanFileFilters;
  allImages: IpisFileV2.Type[];
  allDocuments: IpisFileV2.Type[];
  allFiles: IpisFileV2.Type[];
  uploadFiles: (files: CustomFileInputFile[]) => void;
  deleteFile: (file: IpisFileV2.Type) => void;
  applyFilters: (filters: THandymanFileFilters) => {
    images: IpisFileV2.Type[];
    documents: IpisFileV2.Type[];
  };
  isLoading: boolean;
  isError: boolean;
  isErrorWithoutData: boolean;
  isErrorWithData: boolean;
  permissions: FilePermissions;
  hasServiceContract: boolean;
  hasPreparationsForm: boolean;
}>({} as never);

type Props = {
  children?: React.ReactNode;
} & (
  | {
      as: "salesTeam";
      workOrder: WorkOrder.CustomerType;
      preparationForm: HandymanGetPreparationFormOutput | undefined;
    }
  | {
      as: "handyman";
      workOrder: WorkOrder.HandymanWithPermissionsType;
      preparationForm: HandymanGetPreparationFormOutput | undefined;
    }
);

const DecoupledWorkOrderFileContextProvider = (props: Props) => {
  //const { order } = useContext(HandymanWorkOrderRouteContext);

  const { workOrderFileRes, relatedToWorkOrderFileRes } =
    useAuthenticatedWorkOrderFiles(props);

  const { uploadFiles, deleteFile } = useAuthenticatedFileHandling({
    ...props,
    workOrderFileRes,
    relatedToWorkOrderFileRes,
  });

  const permissions = useAuthenticatedFilePermissions(props);

  const sortedFiles = useFileSorting({
    workOrderFiles: workOrderFileRes.query.data ?? [],
    relatedFiles: relatedToWorkOrderFileRes.data ?? [],
    preparationForm: props.preparationForm,
  });

  const { applyFilters } = useFileFiltering({ sortedFiles });

  const hasServiceContract =
    props.as === "handyman" && !!props.workOrder.serviceContract;

  const isLoading =
    workOrderFileRes.query.isLoading || relatedToWorkOrderFileRes.isLoading;

  const isError =
    workOrderFileRes.query.isError || relatedToWorkOrderFileRes.isError;
  const isErrorWithoutData =
    isError &&
    !workOrderFileRes.query.data?.length &&
    !relatedToWorkOrderFileRes.data?.length;
  const isErrorWithData = isError && !isErrorWithoutData;

  const defaultFilterValues: THandymanFileFilters = {
    workOrderFiles: true,
    serviceContractFiles: true,
    workOrderLineItemFiles: true,
    opportunityLineItemFiles: true,
    productPackageFiles: true,
    preparationFormFiles: !!props.workOrder.externalCustomerPreparationForm,
  };

  return (
    <HandymanWorkOrderFileContext.Provider
      value={{
        ...sortedFiles,
        hasServiceContract,
        hasPreparationsForm: !!props.workOrder.externalCustomerPreparationForm,
        workOrderFileRes,
        relatedToWorkOrderFileRes,
        uploadFiles,
        defaultFilterValues,
        deleteFile,
        applyFilters,
        isLoading,
        isError,
        isErrorWithoutData,
        isErrorWithData,
        permissions,
      }}
    >
      {props.children}
    </HandymanWorkOrderFileContext.Provider>
  );
};

export default DecoupledWorkOrderFileContextProvider;
