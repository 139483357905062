import { IpisFileV2 } from "@eljouren/file-schemas/build";
import ClientUtils from "./../../../../utils/ClientUtils";
import IpisFileRelationDiv from "./IpisFileRelationFieldset";
import IpisFileRelationLabel from "./IpisFileRelationLabel";

import { DateHelper } from "@eljouren/utils";
import { CheckFilePermissionsCallback } from "../../../../types/file-permissions";
import { AppButton } from "../../../common/buttons/AppButton";
import IpisFileIcon from "../../../files/IpisFileIcon";
import AppTrashIcon from "../../../icons/AppTrashIcon";

interface Props {
  className?: string;
  file: IpisFileV2.Type;
  filePermissionCallback: CheckFilePermissionsCallback;
  onDelete: (file: IpisFileV2.Type) => void;
}

const IpisFileListItem = (props: Props) => {
  const description = props.file.description;
  const date = props.file.createdDate;
  const canDelete = props.filePermissionCallback(props.file).canDelete;

  const rel = props.file.relations ?? [];
  const relatedToWorkOrder = rel.some(
    (relation) => relation.type === "workOrder"
  );

  return (
    <li
      data-related-to-work-order={relatedToWorkOrder ? "true" : "false"}
      className={ClientUtils.twClassNames(
        props.className,
        "grid w-full grid-cols-1 items-center gap-2 border-b last:border-b-0 lg:grid-cols-[minmax(0,1fr),auto]"
      )}
    >
      <a
        className={ClientUtils.twClassNames(
          "flex w-full flex-col gap-2  pb-2 "
        )}
        href={props.file.src}
        target="_blank"
        rel="noreferrer"
      >
        <span className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-2">
          <IpisFileIcon size={24} mimeType={props.file.mimeType} />
          <span className="text-sm md:text-base">
            {props.file.nameWithExtension}
          </span>
        </span>
        <span className="flex flex-col justify-between gap-2">
          {description && (
            <p className="mr-auto w-full overflow-hidden break-words rounded bg-main-bg-light/5 p-2 text-sm">
              {description}
            </p>
          )}
          {rel.length === 1 && (
            <IpisFileRelationLabel relation={rel[0]} file={props.file} />
          )}
          {rel.length > 1 && <IpisFileRelationDiv file={props.file} />}
          {date && (
            <span className={ClientUtils.twClassNames("text-sm italic")}>
              {new DateHelper(date).dateInputFormat}
            </span>
          )}
        </span>
      </a>
      {canDelete && (
        <AppButton
          contentClassName={ClientUtils.twClassNames(
            "my-auto mr-auto flex gap-2 px-6 py-2"
          )}
          onClick={() => props.onDelete(props.file)}
        >
          <AppTrashIcon size={20} />
          <span>Ta bort fil</span>
        </AppButton>
      )}
    </li>
  );
};

export default IpisFileListItem;
