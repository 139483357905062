import { IpisFileV2 } from "@eljouren/file-schemas";
import { HandymanGetPreparationFormOutput } from "../../_model/api-clients/preparation-form/preparation-form-api-client-types";
import {
  FormElementAnswer,
  IpisFormElementIsolatedAnswer,
} from "@eljouren/domain";
import { useMemo } from "react";

type FileRelationType =
  | "workOrderLineItem"
  | "opportunityLineItem"
  | "productPackage"
  | "serviceContract";

export type FileWithPreparationFormRelation = IpisFileV2.Type & {
  isLinkedToPreparationsForm?: boolean;
};

function separateImagesAndDocuments(files: IpisFileV2.Type[]): {
  images: IpisFileV2.Type[];
  documents: IpisFileV2.Type[];
} {
  return files.reduce<{
    images: IpisFileV2.Type[];
    documents: IpisFileV2.Type[];
  }>(
    (acc, file) => {
      const target =
        file.collectionType === "images" ? acc.images : acc.documents;
      target.push(file);
      return acc;
    },
    { images: [], documents: [] }
  );
}

function filterFilesByRelationType(
  files: IpisFileV2.Type[],
  relationType: FileRelationType
): IpisFileV2.Type[] {
  return files.filter((file) =>
    file.relations?.some((rel) => rel.type === relationType)
  );
}

type Args = {
  workOrderFiles: IpisFileV2.Type[];
  relatedFiles: IpisFileV2.Type[];
  preparationForm?: HandymanGetPreparationFormOutput;
};

export type UseFileSortingOutput = {
  workOrderImages: IpisFileV2.Type[] | undefined;
  workOrderDocuments: IpisFileV2.Type[] | undefined;
  workOrderLineItemImages: IpisFileV2.Type[] | undefined;
  workOrderLineItemDocuments: IpisFileV2.Type[] | undefined;
  opportunityLineItemImages: IpisFileV2.Type[] | undefined;
  opportunityLineItemDocuments: IpisFileV2.Type[] | undefined;
  productPackageImages: IpisFileV2.Type[] | undefined;
  productPackageDocuments: IpisFileV2.Type[] | undefined;
  serviceContractImages: IpisFileV2.Type[] | undefined;
  serviceContractDocuments: IpisFileV2.Type[] | undefined;
  preparationFormImages: FileWithPreparationFormRelation[] | undefined;
  allImages: FileWithPreparationFormRelation[];
  allDocuments: FileWithPreparationFormRelation[];
  allFiles: FileWithPreparationFormRelation[];
};

export function useFileSorting(args: Args): UseFileSortingOutput {
  // Memoize the initial image/document separation
  const workOrderSeparated = useMemo(
    () => separateImagesAndDocuments(args.workOrderFiles),
    [args.workOrderFiles]
  );

  const relatedSeparated = useMemo(
    () => separateImagesAndDocuments(args.relatedFiles),
    [args.relatedFiles]
  );

  const { images: workOrderImages, documents: workOrderDocuments } =
    workOrderSeparated;
  const { images: relatedImages, documents: relatedDocuments } =
    relatedSeparated;

  // Memoize preparation form images
  const preparationFormImages = useMemo(() => {
    const answers = args.preparationForm?.answers;
    if (!answers) {
      return [];
    }

    const imageAnswers = answers.filter(
      (ans): ans is IpisFormElementIsolatedAnswer.ClientSideImageGroupType =>
        ans.type === "image-group"
    );

    const images = imageAnswers.flatMap((ans) => ans.answer.images);
    const uploadedImages = images.filter(
      (img): img is FormElementAnswer.PostUploadImageType =>
        img.state === "postupload"
    );

    return uploadedImages.map((el) => ({
      ...el,
      isLinkedToPreparationsForm: true,
    }));
  }, [args.preparationForm?.answers]);

  // Memoize file categories
  const fileCategories = useMemo(() => {
    const categories: Record<
      FileRelationType,
      { images: IpisFileV2.Type[]; documents: IpisFileV2.Type[] }
    > = {
      workOrderLineItem: { images: [], documents: [] },
      opportunityLineItem: { images: [], documents: [] },
      productPackage: { images: [], documents: [] },
      serviceContract: { images: [], documents: [] },
    };

    (Object.keys(categories) as FileRelationType[]).forEach((relationType) => {
      categories[relationType].images = filterFilesByRelationType(
        relatedImages,
        relationType
      );
      categories[relationType].documents = filterFilesByRelationType(
        relatedDocuments,
        relationType
      );
    });

    return categories;
  }, [relatedImages, relatedDocuments]);

  // Memoize aggregated arrays
  const { allImages, allDocuments, allFiles } = useMemo(() => {
    const images: FileWithPreparationFormRelation[] = [
      ...workOrderImages,
      ...relatedImages,
      ...preparationFormImages,
    ];
    const documents: FileWithPreparationFormRelation[] = [
      ...workOrderDocuments,
      ...relatedDocuments,
    ];

    return {
      allImages: images,
      allDocuments: documents,
      allFiles: [...images, ...documents],
    };
  }, [
    workOrderImages,
    workOrderDocuments,
    relatedImages,
    relatedDocuments,
    preparationFormImages,
  ]);

  return {
    workOrderImages,
    workOrderDocuments,
    workOrderLineItemImages: fileCategories.workOrderLineItem.images,
    workOrderLineItemDocuments: fileCategories.workOrderLineItem.documents,
    opportunityLineItemImages: fileCategories.opportunityLineItem.images,
    opportunityLineItemDocuments: fileCategories.opportunityLineItem.documents,
    productPackageImages: fileCategories.productPackage.images,
    productPackageDocuments: fileCategories.productPackage.documents,
    serviceContractImages: fileCategories.serviceContract.images,
    serviceContractDocuments: fileCategories.serviceContract.documents,
    preparationFormImages,
    allImages,
    allDocuments,
    allFiles,
  };
}
