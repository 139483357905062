import { LiveBookingSuggestions } from "@eljouren/domain";
import { DateHelper } from "@eljouren/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import { eachDayOfInterval } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { Controller, FieldErrors } from "react-hook-form";
import GoogleAddressInput from "../../../components/GoogleAdressInput";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppFormCheckbox } from "../../../components/common/checkboxes/AppFormCheckbox";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import { AppFormSelect } from "../../../components/common/select/AppFormSelect";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import { AppTextField } from "../../../components/common/text-fields/AppTextField";
import { useLoading } from "../../../hooks/hooks";
import { useApiClients } from "../../../hooks/use-api-clients";
import { useSearchParamForm } from "../../../hooks/use-search-params";
import ClientUtils from "./../../../utils/ClientUtils";

/* type Qualification = {
  name: string;
  isOnDuty: boolean;
} */

const qualifications = [
  {
    name: "Avtal - Laddbox",
    isOnDuty: false,
  },
  {
    name: "EL - Planerat arbete",
    isOnDuty: false,
  },
  {
    name: "VVS - Planerat arbete",
    isOnDuty: false,
  },
  {
    name: "EL - tillgänglig jour",
    isOnDuty: true,
  },
  {
    name: "VVS - tillgänglig jour",
    isOnDuty: true,
  },
  {
    name: "Spolbil",
    isOnDuty: true,
  },
  {
    name: "IPIS Direkt - EL",
    isOnDuty: false,
  },
  {
    name: "IPIS Direkt - VVS",
    isOnDuty: false,
  },
  {
    name: "IPIS Direkt - Spol",
    isOnDuty: false,
  },
  {
    name: "Lås - Tillgänglig Jour",
    isOnDuty: true,
  },
  {
    name: "Snickare",
    isOnDuty: false,
  },
  {
    name: "Fixare",
    isOnDuty: false,
  },
] as const;

const FadeInOutMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const currentWeek = new DateHelper().week;
const currentYear = new DateHelper().year;

// Get next 6 weeks, handling year rollover correctly
const weeks = Array.from({ length: 6 }, (_, i) => {
  const helper = new DateHelper().add("weeks", i);
  return helper.week;
});

const startOfCurrentWeek = new DateHelper().startOfWeek;
const endOfCurrentWeek = new DateHelper().endOfWeek;
const currentWeekValidDayInterval = eachDayOfInterval({
  start: startOfCurrentWeek.date,
  end: endOfCurrentWeek.date,
}).filter((date) => {
  const helper = new DateHelper(date);
  return !helper.isWeekend && !helper.isToday;
});

const firstWeekValid = !!currentWeekValidDayInterval.length;

const WeekDayTupleSchema = z.tuple([
  z.boolean(),
  z.boolean(),
  z.boolean(),
  z.boolean(),
  z.boolean(),
  z.boolean(),
  z.boolean(),
]);

type WeekDayTupleType = z.infer<typeof WeekDayTupleSchema>;

const FormValuesSchema = z.object({
  address: z.object({
    name: z.string().min(1),
    latitude: z.number(),
    longitude: z.number(),
  }),
  timeEstimateInMinutes: z.union([
    z.string().min(1).transform(Number),
    z.number().min(1),
  ]),
  qualification: z.string().min(1),
  week: z.union([z.string().min(1).transform(Number), z.number().min(1)]),
  weekDays: WeekDayTupleSchema.refine((val) => {
    return Array.from(val).some((v) => v);
  }),
  useCustomDuration: z.boolean().optional(),
});
type FormValuesType = z.infer<typeof FormValuesSchema>;

const ValueSanitationSchema = FormValuesSchema.partial().extend({
  weekDays: WeekDayTupleSchema.optional(),
});

interface Props {
  className?: string;
  onBookingSuggestionsReturned: (
    bookingSuggestion: LiveBookingSuggestions.Type
  ) => void;
}

const SalesTeamBookingSuggestionsForm = (props: Props) => {
  const form = useSearchParamForm<FormValuesType>({
    formProps: {
      resolver: zodResolver(FormValuesSchema),
    },
    valueSanitizer: ValueSanitationSchema,
    map: {
      timeEstimateInMinutes: {
        key: "tEst",
        treatAs: "number",
      },
      qualification: {
        key: "qual",
        treatAs: "string",
      },
      week: {
        key: "week",
        treatAs: "number",
      },
      weekDays: {
        key: "weekDays",
        treatAs: "custom",
        convertToString: (val: any) => {
          if (!val) {
            return null;
          }
          const str = Array.from(val)
            .map((v) => (v ? "1" : "0"))
            .join("");
          return str;
        },
        convertFromString: (val) => {
          if (!val) {
            return null;
          }
          return val.split("").map((v) => v === "1");
        },
      },
      useCustomDuration: {
        key: "cTime",
        treatAs: "boolean",
      },
      address: {
        key: "addr",
        treatAs: "custom",
        convertToString: (val) => {
          if (!val) {
            return null;
          }
          return `${val.name};${val.latitude};${val.longitude}`;
        },
        convertFromString: (val) => {
          if (!val) {
            return null;
          }
          const [name, latitude, longitude] = val.split(";");
          return {
            name,
            latitude: Number(latitude),
            longitude: Number(longitude),
          };
        },
      },
    },
  });

  const selectedWeek = form.getValues("week");
  const selectedQualification = form.getValues("qualification");

  const isOnDuty = !!qualifications.find(
    (q) => q.name === selectedQualification
  )?.isOnDuty;

  const previousIsOnDuty = useRef<boolean | null>(null);
  const previousWeek = useRef<number | null>(null);
  const isOnDutyChanged =
    previousIsOnDuty.current !== null && previousIsOnDuty.current !== isOnDuty;
  const weekChanged =
    previousWeek.current !== null && previousWeek.current !== selectedWeek;

  function getInterval() {
    let year: number = currentYear;
    if (selectedWeek < currentWeek) {
      year++;
    }

    const referenceDateHelper = new DateHelper()
      .setWeek(selectedWeek ?? currentWeek)
      .setYear(year);
    const startOfWeek = referenceDateHelper.startOfWeek;
    const endOfWeek = referenceDateHelper.endOfWeek;

    return eachDayOfInterval({
      start: startOfWeek.date,
      end: endOfWeek.date,
    });
  }

  const intervalDateOptions = getInterval();

  useEffect(() => {
    if (!isOnDutyChanged && !weekChanged) {
      return;
    }

    const now = new DateHelper().startOfDay.date;

    const dates: WeekDayTupleType = intervalDateOptions.map((date) => {
      const helper = new DateHelper(date);
      if (isOnDuty) {
        return helper.isToday;
      } else {
        return !helper.isWeekend && !helper.isToday && date >= now;
      }
    }) as WeekDayTupleType;

    form.setValue("weekDays", dates);

    if (isOnDuty && selectedWeek !== currentWeek && isOnDutyChanged) {
      form.setValue("week", currentWeek);
    }
  }, [
    selectedWeek,
    selectedQualification,
    isOnDuty,
    isOnDutyChanged,
    weekChanged,
    intervalDateOptions,
    form,
  ]);

  useEffect(() => {
    previousIsOnDuty.current = isOnDuty;
    previousWeek.current = selectedWeek;
  }, [isOnDuty, selectedWeek]);

  const { bookingsRepo } = useApiClients();
  const loader = useLoading();

  async function onSubmit(values: FormValuesType) {
    try {
      const helper = new DateHelper();
      const currentWeek = helper.week;
      const selectedWeek = values.week;

      let year: number = helper.year;
      if (selectedWeek < currentWeek) {
        year++;
      }

      const res = await loader.loadWhilePromise(
        bookingsRepo.findWorkOrderBookingSuggestions({
          ...values,
          year,
          isOnDuty,
        })
      );

      props.onBookingSuggestionsReturned(res);
    } catch (er) {
      console.log(er);
      window.modal.alert({
        title: "Okänt fel vid sökning",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  function onError(errors: FieldErrors<FormValuesType>) {
    if (errors.address) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Välj adress",
        prompt: "Vänligen välj en adress.",
      });
    }
    console.log(errors);
  }

  let weekOptions = weeks.map((week) => ({
    label: `Vecka ${week}`,
    value: week,
  }));

  if (!firstWeekValid) {
    weekOptions = weekOptions.slice(1);
  }

  const useCustomDuration = form.getValues("useCustomDuration");

  return (
    <form
      className={ClientUtils.twClassNames(
        "relative mb-auto flex flex-col items-center justify-center gap-4 transition-opacity",
        loader.isLoading && "pointer-events-none opacity-50",
        props.className
      )}
      onSubmit={form.handleSubmit(onSubmit, onError)}
    >
      {loader.isLoading && <AppLoader className="absolute ml-auto h-12 w-12" />}
      <Controller
        control={form.control}
        name="address"
        render={(props) => {
          return (
            <p className="mb-8 flex w-full flex-col gap-1">
              {/* 
                ToDo:
                *  The label is not actually connected to the input
              */}
              <label className="text-sm" htmlFor="address">
                Sök på adress
              </label>
              <GoogleAddressInput
                defaultValue={props.field.value?.name}
                onBlur={props.field.onBlur}
                className="border"
                onAddressSelected={(address) => {
                  props.field.onChange({
                    name: address.formattedAddress,
                    ...address,
                  });
                }}
              />
              <span className="pl-1 text-xs text-dark-gray text-opacity-80">
                Klicka på adressen i dropdownen för att välja adress.
              </span>
            </p>
          );
        }}
      />

      <AppTextField
        label="Vald adress"
        htmlAttributes={{
          value: form.getValues("address")?.name ?? "",
          readOnly: true,
          disabled: true,
        }}
      />

      <AnimatePresence exitBeforeEnter>
        <motion.fieldset
          className="h-16 w-full"
          {...FadeInOutMotionProps}
          key={useCustomDuration ? "customDuration" : "predefinedJobTypes"}
        >
          {useCustomDuration && (
            <AppFormTextField
              register={form.register}
              name="timeEstimateInMinutes"
              label="Tidsåtgång i minuter"
              htmlAttributes={{
                type: "number",
                placeholder: "Ange tidsåtgång i minuter",
              }}
            />
          )}
          {!useCustomDuration && (
            <AppFormSelect
              register={form.register}
              name="timeEstimateInMinutes"
              label="Typ av arbete"
              options={[
                { label: "Lätt arbete (3h)", value: 180 },
                { label: "Svårt arbete (4h)", value: 240 },
                { label: "Planerat arbete (2h)", value: 120 },
              ]}
            />
          )}
        </motion.fieldset>
      </AnimatePresence>

      <AppFormCheckbox
        register={form.register}
        className="ml-auto text-sm"
        name="useCustomDuration"
        label="Ange egen tidsåtgång"
        type="checkbox"
      />

      <AppFormSelect
        register={form.register}
        name="qualification"
        label="Kompetens"
        options={qualifications.map((q) => ({
          label: q.name,
          value: q.name,
        }))}
      />
      <AppFormSelect
        register={form.register}
        name="week"
        label="Vecka"
        options={weekOptions}
      />

      <Controller
        control={form.control}
        name="weekDays"
        render={(props) => {
          const current = props.field.value ?? [];
          const interval = intervalDateOptions;

          return (
            <>
              <ul
                className={ClientUtils.twClassNames(
                  "flex",
                  !props.fieldState.error && "mb-12"
                )}
              >
                {interval.map((date, i) => {
                  const helper = new DateHelper(date);
                  const dayOfWeek = helper.dayOfWeek;
                  const isSelected = current[i];
                  const now = new DateHelper().startOfDay.date;
                  const disabled = date < now;

                  function onClick() {
                    if (disabled) {
                      return;
                    }

                    props.field.onChange([
                      ...current.slice(0, i),
                      !isSelected,
                      ...current.slice(i + 1),
                    ]);
                  }

                  return (
                    <li
                      key={date.getTime()}
                      onClick={onClick}
                      className={ClientUtils.twClassNames(
                        "w-24 border border-black p-2 text-center",
                        !disabled && "cursor-pointer",
                        !disabled &&
                          !isSelected &&
                          "bg-brand-blue-100 text-dark-gray text-opacity-80",
                        !disabled &&
                          isSelected &&
                          "bg-brand-blue-500 font-bold text-off-white",
                        disabled && "pointer-events-none opacity-50"
                        //isSelected && "border-green-600"
                      )}
                    >
                      {dayOfWeek}
                    </li>
                  );
                })}
              </ul>
              {!!props.fieldState.error && (
                <span className="h-8 text-sm text-red-600">
                  Välj minst en veckodag
                </span>
              )}
            </>
          );
        }}
      />

      <AppButton
        className="mt-8 w-full"
        type="submit"
        disabled={loader.isLoading}
      >
        Hitta bokningsförslag
      </AppButton>
    </form>
  );
};

export default SalesTeamBookingSuggestionsForm;
