import { WorkOrder } from "@eljouren/domain";
import { IpisFileV2 } from "@eljouren/file-schemas";
import {
  FilePermissions,
  SingleFilePermissions,
} from "../../types/file-permissions";

type Args =
  | {
      workOrder: WorkOrder.HandymanWithPermissionsType;
      as: "handyman";
    }
  | {
      as: "salesTeam";
      workOrder: WorkOrder.CustomerType;
    };

export function useAuthenticatedFilePermissions(props: Args): FilePermissions {
  const allowedToHandle =
    props.as === "salesTeam" || props.workOrder.allowedToHandleOrder;
  const canUpload = !props.workOrder.isFinished && allowedToHandle;

  function checkFilePermissions(file: IpisFileV2.Type): SingleFilePermissions {
    const relatedToWorkOrder = !!file.relations?.every(
      (rel) => rel.type === "workOrder"
    );
    const uploadedByHandymanOrCustomer =
      file.uploadedBy === "worker" || file.uploadedBy === "customer";

    const hasTag = !!file.tag;
    return {
      canDelete:
        canUpload &&
        relatedToWorkOrder &&
        uploadedByHandymanOrCustomer &&
        !hasTag,
    };
  }

  return {
    canUpload,
    forFile: checkFilePermissions,
  };
}
