import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ClientUtils from "../../../../utils/ClientUtils";
import { IpisButton } from "@ipis/client-essentials";
import AppPages from "../../../common/pages/AppPages";
import RichTextEditorContext from "../../../rich-text-editor/RichTextEditorContext";
import { WizardFormInference } from "../builder/input-builders/common-wizard-fields";
import WizardFormInputRenderer from "./WizardFormInputRenderer";
import { UseWizardFormReturn } from "./use-wizard-form";
import { WizardFormContext, WizardFormElement } from "./wizard-form-types";
import { FormRenderContext } from "../ChecklistContexts";

interface Props<T extends WizardFormInference.Config> {
  className?: string;
  hookReturn: UseWizardFormReturn<T>;
}

const WizardForm = <T extends WizardFormInference.Config>(props: Props<T>) => {
  const sectionRef = useRef<any>();

  const [focusedState, setFocusedState] = useState<Record<string, boolean>>({});

  function isFocused(el: WizardFormElement) {
    return !!focusedState[el.id];
  }

  function setFocus(el: WizardFormElement, focused: boolean) {
    setFocusedState((oldState) => {
      return { ...oldState, [el.id]: focused };
    });
  }

  useEffect(() => {
    const firstFocusableElement = sectionRef.current.querySelector(
      "input, textarea, button"
    );
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  }, []);

  const hasGoneThroughWalkthrough = props.hookReturn.hasGoneThroughWalkthrough;

  return (
    <RichTextEditorContext.Provider
      value={{ editors: props.hookReturn.richTextEditors }}
    >
      <WizardFormContext.Provider
        value={{
          hasGoneThroughWalkthrough,
          form: props.hookReturn.form,
          isFocused,
          setFocus,
          validatedChecklistDo: props.hookReturn.validatedChecklistDo,
          checklist: props.hookReturn.fauxChecklist,
        }}
      >
        <FormRenderContext.Provider
          value={{
            useCompactLayout: true,
          }}
        >
          <motion.section
            className={ClientUtils.classNames(
              "grid",
              !hasGoneThroughWalkthrough && "grid-rows-[auto,minmax(0,1fr)]",
              hasGoneThroughWalkthrough && "grid-rows-1"
            )}
            ref={sectionRef}
          >
            {!hasGoneThroughWalkthrough && (
              <motion.header className="flex min-h-[150px] items-center justify-center">
                <p className="text-sm">
                  Use <strong>Tab</strong> to go forward, and{" "}
                  <strong>Shift + Tab</strong> to go back. You can select inputs
                  manually by clicking on them.
                </p>
              </motion.header>
            )}
            <AppPages
              pageIndex={props.hookReturn.pageIndex}
              onlyMain
              mainGridRow={hasGoneThroughWalkthrough ? 1 : 2}
            >
              <motion.form
                className={ClientUtils.classNames(
                  "flex flex-col gap-8",
                  props.className
                )}
                onSubmit={props.hookReturn.onSubmitListener}
              >
                {props.hookReturn.currentConfig.map((element) => {
                  return (
                    <WizardFormInputRenderer
                      key={element.id}
                      element={element}
                    />
                  );
                })}

                <motion.footer className="flex flex-col justify-end gap-2 py-12">
                  <IpisButton
                    label={
                      props.hookReturn.canGoForward() ? "Nästa sida" : "Spara"
                    }
                    type="submit"
                  />
                  {props.hookReturn.canGoBack() && (
                    <IpisButton
                      variant="text"
                      label="Gå tillbaka"
                      onClick={() => props.hookReturn.goBack()}
                    />
                  )}
                </motion.footer>
              </motion.form>
            </AppPages>
          </motion.section>
        </FormRenderContext.Provider>
      </WizardFormContext.Provider>
    </RichTextEditorContext.Provider>
  );
};

export default WizardForm;
