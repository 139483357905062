import React from "react";
import withSalesGuid from "../../../components/auth/hocs/withSalesGuid";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useApiClients } from "../../../hooks/use-api-clients";
import { WorkOrder } from "@eljouren/domain";

import SalesTeamWorkOrderPage from "./SalesTeamWorkOrderPage";
import { useQuery, UseQueryResult } from "react-query";
import DecoupledWorkOrderFileContextProvider from "../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import { useQueryWrapper } from "@ipis/client-essentials";

export const SalesTeamWorkOrderRouteContext = React.createContext<{
  orderRes: UseQueryResult<WorkOrder.CustomerType>;
  order: WorkOrder.CustomerType;
}>({} as never);

const SalesTeamWorkOrderRoute = () => {
  const { workOrderRepo, ipisFormRepo, workOrderFileRepo } = useApiClients();
  const res = useQuery("workOrderByStaffGuid", () =>
    workOrderRepo.getByStaffGuid()
  );

  const order = res.data;
  const form = order?.externalCustomerPreparationForm;
  const preparationFormRes = useQueryWrapper({
    queryKey: ["preparationForm", order?.orderId],
    queryFn: () =>
      ipisFormRepo.getPreparationFormForHandymanOrStaff({
        /* 
            This won't be enabled if the order doesn't exist        
        */
        workOrderId: order?.orderId!,
        fileRepo: workOrderFileRepo,
      }),
    enabled: !!form && !!form.completedDate,
  });

  const showError = res.isError || (!res.isLoading && !res.data);

  return (
    <LoadingContent
      loading={res.isFetching && !res.data}
      renderContentSeparately
    >
      {showError && (
        <SomethingWentWrong
          error={res.error}
          description="Denna order går inte att hämta just nu, vilket förmodligen betyder att den inte finns."
        />
      )}
      {!showError && res.data && (
        <SalesTeamWorkOrderRouteContext.Provider
          value={{ orderRes: res, order: res.data }}
        >
          <DecoupledWorkOrderFileContextProvider
            as="salesTeam"
            workOrder={res.data}
            preparationForm={preparationFormRes.data}
          >
            <SalesTeamWorkOrderPage />
          </DecoupledWorkOrderFileContextProvider>
        </SalesTeamWorkOrderRouteContext.Provider>
      )}
    </LoadingContent>
  );
};

export default withSalesGuid(SalesTeamWorkOrderRoute);
