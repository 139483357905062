import { IpisFormElementOption } from "@eljouren/domain";
import { FormInputContext, IpisButton } from "@ipis/client-essentials";
import { useContext, useRef } from "react";
import {
  FileUploadFilter,
  UseFileUploadProps,
  useFileInputAccept,
  useFileUpload,
} from "../../../hooks/file-upload-hooks";
import ExtensionsAndFileSizeSpan from "../../__v2__/form-components/ExtensionsAndFileSizeSpan";
import DelayedRenderImage from "../../__v2__/utils/DelayedRenderImage";
import AppImageIcon from "../../icons/AppImageIcon";
import AppMinusIcon from "../../icons/AppMinusIcon";

interface Props<F extends FileUploadFilter = "all"> {
  className?: string;
  prompt: IpisFormElementOption.ImagePromptType;
  id: string;
  fileUploadProps: Omit<UseFileUploadProps<F>, "ref" | "allow">;
}

const ChecklistImageUploadComponent = <F extends FileUploadFilter>(
  props: Props<F>
) => {
  const ctx = useContext(FormInputContext);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const accept = useFileInputAccept({
    allow: "images",
    removeHeic: true,
  });

  const fileUploadReturn = useFileUpload({
    allow: "images",
    ref: inputRef,
    createName: (file) => {
      /* 
        Each "image-group" question has 1 or more "prompts" in it.
        Let's say we have a question with id "image-group-1", with three
        prompts like:
        {id: "prompt-1", promptText: "Picture of the front of the house"}
        {id: "prompt-2", promptText: "Picture of the electrical panel"}
        {id: "prompt-3", promptText: "Picture of some electrical thingy"}

        When the form including this question is submitted, an answer will be created in the
        backend that links it to the question, like:
        {
          id: "image-group-answer",
          formElementId: "image-group-1", // Reference to the question
        }

        The images will then be linked to the answer, which in pracice means that
        the recordId supplied to the file server will be "image-group-answer".

        This means that we've only linked the images to the parent question, not the specific prompt.

        Below is a super hacky way of linking the image to the specific prompt. Instead of a more robust tagging system,
        the name of the file is simply set to the promptText. When we fetch the files, we compare the name of the file
        to the promptText, and if they match, we know that the image belongs to that prompt.

        This means that if the promptText changes, or the prompt is removed, we won't be able to connect the image
        to the prompt. The image will still show, but without matching it to the right prompt.

        Of course, a more robust tagging system would be better, but when that will be prioritised is unknown.

        Another reason why this is a very fragile solution is that the max length of the promptText MUST be less than
        or equal to the max length of the file name, otherwise the fileserver will reject the file (or rather, the file
        will fail to upload since the max name validation is actually done in Salesforce).

        While this holds true at the time of writing, this is an example of something that easily could break if one or the other max
        length changes. Currently, the max length of the file name is 255 characters (Filnamn__c) , and the max length of the promptText is 80 characters (Name)

        ToDo:
        - Move the documentation for this to somewhere where it makes more sense.
      */
      return props.prompt.promptText;
    },
    ...props.fileUploadProps,
  });

  function triggerFileInput() {
    inputRef.current?.click();
  }

  const buttonId = props.id + "-file-input-button";

  const hasPhotos = fileUploadReturn.hasFiles;

  return (
    <section className="flex flex-col gap-2">
      <fieldset className="flex flex-col gap-1">
        {!ctx?.readOnly && (
          <input
            id={props.id + "-file-input"}
            aria-describedby={buttonId}
            ref={inputRef}
            type="file"
            className="hidden"
            accept={accept}
            multiple
          />
        )}
        <h3 className="text-lg font-normal text-dark-950">
          {props.prompt.promptText}
          {props.prompt.required && (
            <span className="pl-2 text-base">{"*"}</span>
          )}
        </h3>
        {!hasPhotos && !ctx?.readOnly && (
          <button
            id={buttonId}
            className={
              "flex h-40 w-full flex-col items-center justify-center border border-dashed border-gray-400 text-dark-gray/80"
            }
            onClick={() => {
              triggerFileInput();
            }}
            type="button"
          >
            <AppImageIcon size={32} />
            <span className="text-dark-900">Ladda upp bilder</span>
          </button>
        )}
        {!hasPhotos && !!ctx?.readOnly && (
          <p
            className={
              "flex h-40 w-full flex-col items-center justify-center border border-dashed border-gray-400 text-dark-gray/80"
            }
          >
            {/* <AppImageIcon size={32} /> */}
            <span className="text-dark-900">Inga bilder uppladdade</span>
          </p>
        )}
        {hasPhotos && (
          <ul className="grid min-h-[10rem] grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-2 md:grid-cols-2">
            {fileUploadReturn.currentFiles.map((file) => {
              return (
                <li key={file.guid} className="relative">
                  {file.state === "preupload" && (
                    <DelayedRenderImage
                      delayInMs={1000}
                      src={file.dataUrl}
                      alt={file.meta?.name ?? "Uppladdad bild"}
                      className="h-40 w-full object-cover"
                    />
                  )}
                  {file.state === "postupload" && (
                    <DelayedRenderImage
                      delayInMs={1000}
                      src={file.src}
                      alt={file.name}
                      className="h-40 w-full object-cover"
                    />
                  )}
                  {!ctx?.readOnly && (
                    <button
                      className="absolute right-0 top-0 rounded-bl bg-white p-1"
                      onClick={() => {
                        fileUploadReturn.removeFile(file);
                      }}
                      type="button"
                      aria-label="Ta bort bild"
                    >
                      <AppMinusIcon className="rounded-full border-2 border-black" />
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </fieldset>
      {!ctx?.readOnly && (
        <footer className="flex h-10 flex-col">
          {!hasPhotos && (
            <ExtensionsAndFileSizeSpan
              accept={accept}
              maxSizeString={fileUploadReturn.maxSizeStr}
            />
          )}
          {hasPhotos && (
            <IpisButton
              variant="text"
              label="Lägg till fler bilder"
              onClick={() => {
                triggerFileInput();
              }}
              margin={{
                ml: "auto",
              }}
            />
          )}
        </footer>
      )}
    </section>
  );
};

export default ChecklistImageUploadComponent;
