import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ClientUtils from "./../../utils/ClientUtils";

import { useIsPreviousPageFromCurrentSite } from "../../hooks/hooks";
import { useBundledSearchParamState } from "../../hooks/use-search-params";
import { CheckFilePermissionsCallback } from "../../types/file-permissions";
import ScrollIndicatorContainer from "../ScrollIndicatorContainer";
import MyDialog from "../common/MyDialog";
import { AppLoader } from "../common/loaders/AppLoader";
import SelectableFileGridItem from "./SelectableFileGridItem";
import SelectedFileGridItem from "./SelectedFileGridItem";

export type IpisFileWithAltText = IpisFileV2.Type & {
  alt: string;
};

interface Props<T extends IpisFileWithAltText> {
  className?: string;
  files?: T[];
  isError?: boolean;
  isLoading?: boolean;
  onDelete?: (image: T) => void;
  disableDelete?: boolean;
  filePermissionCallback: CheckFilePermissionsCallback;
}

/* 
  The GridItem component was always used inside the <ul/> component previously, which is why it gets passed props like selected: boolean and whatnot.

  Currently, when an image is selected, it is rendered outside of the <ul/> as a section, and it probably would be better and clearer if the selected image
  was a totally different component.
*/
const SelectableFileGrid = <T extends IpisFileWithAltText>(props: Props<T>) => {
  const files = props.files || [];
  const selectedFileId = useBundledSearchParamState("fil", "");
  const listRef = useRef<HTMLUListElement | null>(null);
  const previousScrollPosition = useRef<{ x: number; y: number } | null>(null);

  const previousPageFromCurrentSite = useIsPreviousPageFromCurrentSite();
  const nav = useNavigate();

  const selectedFile = files.find((file) => file.guid === selectedFileId.value);

  function selectFile(file: IpisFileWithAltText) {
    selectedFileId.set(file.guid, {
      replace: !!selectedFileId.value,
    });
  }

  function deselectFile() {
    if (previousPageFromCurrentSite) {
      //dialogRef.current?.close();
      nav(-1);
    } else {
      selectedFileId.set("", {
        replace: true,
      });
    }
  }

  async function onDelete(image: T | null) {
    if (!props.disableDelete && image && props.onDelete) {
      try {
        deselectFile();
        await props.onDelete(image);
      } catch (er) {
        //...
      }
    }
  }

  if (props.isLoading) {
    return (
      <section className="flex flex-col gap-3 p-1">
        <AppLoader />
      </section>
    );
  }

  return (
    <section className={ClientUtils.twClassNames("grid h-full")}>
      <main
        className={ClientUtils.twClassNames("h-full w-full overflow-y-auto")}
        style={{
          transform: "translateX(0)",
        }}
      >
        <MyDialog isOpen={!!selectedFile}>
          {selectedFile && (
            <SelectedFileGridItem
              filePermissionCallback={props.filePermissionCallback}
              onShowAll={deselectFile}
              key={"selected"}
              file={selectedFile}
              disableDelete={props.disableDelete}
              onDelete={() => {
                onDelete(selectedFile);
              }}
              allFiles={files}
              selectImage={selectFile}
            />
          )}
        </MyDialog>

        {!!files?.length && !selectedFile && (
          <ScrollIndicatorContainer
            as="div"
            innerClassName="h-full"
            assumeScrollable={files.length >= 3}
            onScrollPositionChanged={(scrollPosition) => {
              previousScrollPosition.current = {
                x: scrollPosition.scrollX,
                y: scrollPosition.scrollY,
              };
            }}
            initialScrollPosition={previousScrollPosition.current}
          >
            <motion.ul
              ref={listRef}
              className={ClientUtils.twClassNames(
                "group relative grid w-full grid-cols-[repeat(auto-fill,minmax(100px,1fr))] gap-2 overflow-x-hidden lg:grid-cols-[repeat(auto-fill,minmax(225px,1fr))]"
              )}
            >
              {files.map((image) => {
                return (
                  <SelectableFileGridItem
                    key={image.src}
                    file={image}
                    customDimensions
                    className="h-32"
                    onClick={() => {
                      if (selectedFileId.value) {
                        return;
                      }
                      selectFile(image);
                    }}
                  />
                );
              })}
            </motion.ul>
          </ScrollIndicatorContainer>
        )}
        {!files?.length && !props.isError && (
          <p className="p-3">Det finns inga filer att visa</p>
        )}
        {!files?.length && props.isError && (
          <p className="p-3">Något gick fel när filerna skulle hämtas</p>
        )}
      </main>
    </section>
  );
};

export default SelectableFileGrid;
