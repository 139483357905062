"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormBoundaries = void 0;
var IpisFormBoundaries = {
    checklist: {
        name: {
            minLength: 1,
            maxLength: 255,
        },
    },
    checklistPage: {
        title: {
            minLength: 3,
            maxLength: 255,
        },
        shorthandTitle: {
            minLength: 3,
            maxLength: 40,
        },
        description: {
            minLength: 0,
            maxLength: 1000,
        },
        preparations: {
            minLength: 0,
            maxLength: 250,
        },
    },
    checklistElement: {
        title: {
            minLength: 1,
            maxLength: 255,
        },
        description: {
            minLength: 0,
            /*
              Validating this is difficult since it's a rich text field, meaning the value is an object with a lot of properties and can not be easily validated with a simple string length check.
              We can validate the raw text value, but it's not the same as the full value.
            */
            rawTextMaxLength: 1000,
            /*
              Kind of an arbitrary number
            */
            stringifiedMaxLength: 40000,
        },
    },
    multipleChoice: {
        option: {
            minLength: 1,
            /*
              This is the max length of the Name field in Salesforce. See ChecklistImageUploadComponent.tsx for more information about this field.
            */
            maxLength: 80,
        },
    },
};
exports.IpisFormBoundaries = IpisFormBoundaries;
