import { useContext, useEffect, useRef } from "react";
import ClientUtils from "../../../../../../utils/ClientUtils";

import { WorkOrderLineItem, WorkOrderProduct } from "@eljouren/domain/build";
import { UUID } from "@eljouren/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import { useBundledState } from "../../../../../../hooks/hooks";
import { useApiClients } from "../../../../../../hooks/use-api-clients";
import HandymanWorkOrderRouteContext from "../../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import { AppButton } from "../../../../../common/buttons/AppButton";
import AppTextButton from "../../../../../common/buttons/AppTextButton";
import SearchFieldSlideDown from "../../../../../common/search/SearchFieldSlideDown";
import { AppFormTextField } from "../../../../../common/text-fields/AppFormTextField";
import AppPlusIcon from "../../../../../icons/AppPlusIcon";
import { HandymanWorkOrderFileContext } from "../../../../files/DecoupledWorkOrderFileContextProvider";
import HandymanLineItemList from "../components/HandymanLineItemList";
import HandymanLineItemContext from "../../../line-items/HandymanLineItemContext";
import { motion } from "framer-motion";

const FormSchema = z.object({
  productId: z.string().optional(),
  quantity: z.number(),
  workOrderId: z.string(),
  subject: z.string().min(1),
  unitPrice: z.number(),
});

type FormType = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  onClose: () => void;
}

const SearchAndManualMaterialSection = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  const lineItemCtx = useContext(HandymanLineItemContext);
  const lineItemsWhenOpened = useRef(
    lineItemCtx.getMaterialLineItems().lineItems
  );

  const { workOrderRepo } = useApiClients();
  const form = useForm<FormType>({
    defaultValues: {
      quantity: 1,
      workOrderId: order.orderId,
    },
    resolver: zodResolver(FormSchema),
  });
  const productTakenFromList = useBundledState(false);

  useEffect(
    () => {
      form.setValue("workOrderId", order.orderId);
    },
    /* 
    Using form as a dependency here used to work, but since react-hook-form 7.54.0, 
    this causes an infinite rerender loop.

    While I will still downgrade react-hook-form back to 7.53.2, Iäve removed the form
    dependency here as a way to avoid this happening again.
  */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order]
  );

  function getNewLineItems() {
    const newLineItems = lineItemCtx
      .getMaterialLineItems()
      .lineItems.filter(
        (el) => !lineItemsWhenOpened.current.find((el2) => el2.id === el.id)
      );
    return newLineItems;
  }

  function onProductClick(product: WorkOrderProduct.Type) {
    form.setValue("subject", product.name);
    form.setValue("productId", product.id);
    form.setValue("unitPrice", product.unitPrice || 0);
    form.setValue("quantity", 1);
    productTakenFromList.set(true);
  }

  function onReset() {
    form.reset();
    productTakenFromList.set(false);
  }

  /* 
    ToDo: Move this into the context.
  */
  async function onSubmit(values: FormType) {
    onReset();

    let entry: WorkOrderLineItem.NewEntryType;
    const materialBase: Omit<
      WorkOrderLineItem.HandymanType,
      "isCustom" | "productId"
    > = {
      id: UUID.generate().value,
      name: values.subject,
      quantity: values.quantity,
      addedByHandyman: true,
      unit: "st",
      createdDate: new Date(),
      approvalState: "notApproved",
      lastModifiedDate: new Date(),
      linkedMaterialApprovalImages: [],
      permissions: {
        canBeCanceled: false,
        canBeRemoved: true,
        canEditQuantity: true,
      },
      compensationDetails: {
        compensationPerUnit: values.unitPrice,
        showCompensation: true,
      },
      customerPriceDetails: {
        hasCustomerPrice: false,
      },
      createdFor: "handyman",
      isInactive: false,
      type: "material",
    };

    let lineItem: WorkOrderLineItem.HandymanType;

    if (productTakenFromList.value && values.productId) {
      entry = {
        productId: values.productId,
        quantity: values.quantity,
        workOrderId: values.workOrderId,
      };
      lineItem = {
        ...materialBase,
        productId: values.productId,
        isCustom: false,
      };
    } else {
      entry = {
        subject: values.subject,
        unitPrice: values.unitPrice,
        quantity: values.quantity,
        workOrderId: values.workOrderId,
      };
      lineItem = {
        ...materialBase,
        isCustom: true,
      };
    }

    try {
      await lineItemCtx.lineItemRes.mutate({
        callback: () => workOrderRepo.reportMaterial(entry),
        mutableOptimisticUpdate: (prev) => {
          if (!prev) {
            prev = [lineItem];
          } else if (lineItem.isCustom) {
            prev.push(lineItem);
          } else {
            const prevProduct = prev.find(
              (el) => el.productId === values.productId
            );
            if (prevProduct) {
              prevProduct.quantity += values.quantity;
            } else {
              prev.push(lineItem);
            }
          }
        },
      });
      /*
          (Previously: It would be better if this happens automatically when material are added, but the related files
          are in a higher context than the materials.)

          ToDo: 
          * These are now the same context, which means we could make this happen automatically.
      */
      fileCtx.relatedToWorkOrderFileRes.refetch();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att lägga till material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  function onError(errors: any) {
    console.log({ errors });
  }
  return (
    <motion.aside
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={ClientUtils.twClassNames(
        "absolute z-50 grid h-full w-full grid-rows-[auto,minmax(0,1fr),auto] gap-2 bg-off-white/80 p-2 backdrop-blur sm:p-4",
        props.className
      )}
      id="materialFreeChoiceSection"
    >
      <header className="cursor-pointer rounded border-b py-2 text-sm font-bold">
        <h3>Sök och manuellt material</h3>
      </header>
      <main className="flex h-full flex-col gap-4 overflow-auto py-4">
        <SearchFieldSlideDown
          className="rounded border border-black/40"
          placeholder="Sök på material"
          fetch={async (query: string) =>
            workOrderRepo.searchForMaterials({
              query,
              workOrderId: order.orderId,
            })
          }
          id="materialSearchField"
          Render={(props) => {
            if (props.data && props.data.length) {
              return (
                <ul className="flex flex-col gap-1">
                  {props.data.map((product) => {
                    return (
                      <li
                        className="flex cursor-pointer flex-col rounded border-2 border-transparent p-1 hover:border-black"
                        key={product.id}
                        onClick={() => {
                          props.close();
                          onProductClick(product);
                        }}
                      >
                        <span className="text-sm font-bold">
                          {product.name}
                        </span>
                        {product.unitPrice !== undefined && (
                          <span className="text-sm italic">
                            {product.unitPrice}:- / st
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              );
            } else {
              return <span>Inga resultat</span>;
            }
          }}
        />
        <form
          onSubmit={form.handleSubmit(onSubmit, onError)}
          className="grid grid-cols-2 grid-rows-[auto,auto,40px,auto] gap-2 rounded  rounded-t-lg border bg-off-white p-4 py-2 shadow md:py-4"
        >
          <AppFormTextField
            register={form.register}
            name="subject"
            label="Namn"
            className="col-span-2"
            htmlAttributes={{
              readOnly: productTakenFromList.value,
              required: true,
            }}
          />
          <AppFormTextField
            register={form.register}
            className="col-span-2"
            name="unitPrice"
            label="Pris per enhet"
            htmlAttributes={{
              readOnly: productTakenFromList.value,
              required: true,
              type: "number",
            }}
          />

          <fieldset className="col-span-2 flex w-full items-center gap-2">
            <AppFormTextField
              register={form.register}
              name="quantity"
              className="ml-auto w-20"
              htmlAttributes={{
                type: "number",
                id: "extraMaterialQuantity",
                min: 1,
                //readOnly: productTakenFromList.value,
              }}
            />
            <label htmlFor="extraMaterialQuantity">st</label>
            <AppTextButton
              type="submit"
              onClick={form.handleSubmit(onSubmit, onError)}
              disabled={
                lineItemCtx.lineItemRes.isLoading ||
                lineItemCtx.lineItemRes.query.isFetching
              }
            >
              <AppPlusIcon variant="circle" size={35} />
            </AppTextButton>
          </fieldset>
          <AppTextButton
            className="col-span-2 ml-auto text-sm"
            onClick={onReset}
          >
            Återställ
          </AppTextButton>
        </form>

        {getNewLineItems().length > 0 && (
          <section>
            <header>
              <h3>Nytt material</h3>
            </header>
            <main>
              <HandymanLineItemList
                data={getNewLineItems()}
                isLoading={false}
              />
            </main>
          </section>
        )}
      </main>
      <footer className="flex flex-col">
        <AppButton onClick={props.onClose}>Stäng fönster</AppButton>
      </footer>
    </motion.aside>
  );
};

export default SearchAndManualMaterialSection;
