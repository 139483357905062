import { IpisForm, IpisFormBoundaries, WorkOrder } from "@eljouren/domain";
import { IpisButton, classNames } from "@ipis/client-essentials";
import { useContext } from "react";
import PreparationFormApiClient from "../../../../../../_model/api-clients/preparation-form/PreparationFormApiClient";
import useMutableQuery from "../../../../../../hooks/use-mutatable-query";
import { PreparationFormContext } from "../../../../../../routes/customer/preparations-route/CustomerWorkOrderPreparationsRouteV2";
import ClientUtils from "../../../../../../utils/ClientUtils";
import KeyboardShortcutAppButton from "../../../../../__v2__/buttons/KeyboardShortcutAppButton";
import EditableText from "../../../../../__v2__/utils/EditableText";
import MyDialog from "../../../../../common/MyDialog";
import PreChecklistComponent from "../../../pre-checklist/PreChecklistComponent";
import FormBuilderContext from "../../FormBuilderContext";
import EditableFormComponent from "../../editable-checklist/EditableFormComponent";
import AddQuestionButtonsSidebar from "./AddQuestionButtonsSidebar";
import ConditionalLogicFormDialog from "./ConditionalLogicFormDialog";
import EditQuestionFormDialog from "./EditQuestionFormDialog";
import { FormEditorContext } from "./FormEditorContextProvider";
import NewQuestionFormDialog from "./NewQuestionFormDialog";

interface Props {
  className?: string;
}

const FormEditor = (props: Props) => {
  const builderCtx = useContext(FormBuilderContext);
  const editorCtx = useContext(FormEditorContext);

  function togglePreviewModal() {
    editorCtx.setShowPreviewModal((prev) => !prev);
  }

  function closePreviewModal() {
    editorCtx.setShowPreviewModal(false);
  }

  const form = editorCtx.form;

  function onSubmit(values: { [key: string]: any }) {
    window.alert("Submit!");
  }

  const canAddQuestions = !!form && form.pages.length > 0;

  /*   function getCustomerType(form: IpisForm.ShellType): string {
    switch (form.type) {
      case "EXTERNAL_CUSTOMER_WORK_ORDER_PREPARATION_FORM":
        return "Extern";
      case "internal-form":
        throw new Error(
          "Internal forms are not supported - a new schema should be created that can be used without the need for this check"
        );
    }
  }

  function getObjectType(form: IpisForm.ShellType): string {
    switch (form.type) {
      case "EXTERNAL_CUSTOMER_WORK_ORDER_PREPARATION_FORM":
        return "Arbetsorder";
      case "internal-form":
        throw new Error(
          "Internal forms are not supported - a new schema should be created that can be used without the need for this check"
        );
    }
  }

  function getConnectionType(form: IpisForm.ShellType): string {
    if (!form.connection) {
      return "Saknas";
    }

    return `${form.connection.productPackageName}`;
  } */

  const showPreview = !!form && editorCtx.showPreviewModal;

  return (
    <>
      <div className="grid h-full grid-cols-[minmax(0,1fr),auto] grid-rows-[auto,minmax(0,1fr)] overflow-hidden ">
        <header
          className={classNames(
            "col-span-2 grid grid-cols-[minmax(0,1fr),auto] items-center gap-x-4 border-b p-2"
          )}
        >
          <EditableText
            minLength={IpisFormBoundaries.checklist.name.minLength}
            maxLength={IpisFormBoundaries.checklist.name.maxLength}
            as="h3"
            value={form?.name ?? "Skapa checklista"}
            onSubmit={(newName) => {
              editorCtx.controls.renameForm({ newName });
            }}
          />
          {/*   {!!form && (
            <div className="col-start-1 row-start-2 flex items-center gap-2 text-sm text-semi-faded">
              <span>
                <b>Kundtyp</b> {getCustomerType(form)}
              </span>
              <span>
                <b>Objekt</b> {getObjectType(form)}
              </span>
              <span>
                <b>Koppling</b> {getConnectionType(form)}
              </span>
            </div>
          )} */}
          <div className={classNames("flex items-center gap-2")}>
            <IpisButton
              variant="text"
              disabled={!form}
              label="Visa förhandsvisning"
              onClick={togglePreviewModal}
            />
            <IpisButton
              label="Återställ"
              disabled={!editorCtx.isDirty()}
              variant="tonal"
              onClick={() => {
                editorCtx.controls.resetToLatestSnapshot();
              }}
            />

            <KeyboardShortcutAppButton
              keyboardShortcut={{
                key: "s",
                ctrlKey: true,
                preventDefault: true,
              }}
              buttonProps={{
                variant: "secondary-on-light-background",
                disabled: !editorCtx.isDirty() || builderCtx.isLoading,

                loading: builderCtx.isLoading,
                label: "Spara ändringar",
                onClick: () => {
                  editorCtx.saveForm();
                },
              }}
            />
          </div>
        </header>

        <div
          className={ClientUtils.classNames(
            "grid h-full w-full grid-cols-1 grid-rows-1 "
          )}
        >
          <div className="mx-auto grid h-full w-full max-w-screen-lg grid-cols-1 grid-rows-1 flex-col overflow-hidden ">
            {/* 
              If we don't hide this when showing the preview, some weird bugs occur which I'm not 100% sure why they happen.
              My theory is that the inputs in this form and the preview form gets rendered with the same IDs, which makes some
              native browser functionality not work as expected.

              Rendering this conditionally solves the issue.

              Weird thing is I'm not getting any console warnings for duplicate IDs, so I'm not sure if this is the actual issue.
            */}
            {!showPreview && <EditableFormComponent onSubmit={onSubmit} />}
            {/*      <EditableFormComponent onSubmit={onSubmit} /> */}
            <NewQuestionFormDialog
              questionType={editorCtx.showNewQuestionForm}
              close={() => editorCtx.setShowNewQuestionForm(null)}
            />
            <EditQuestionFormDialog
              element={editorCtx.showEditQuestionForm}
              close={() => editorCtx.setShowEditQuestionForm(null)}
            />
            <ConditionalLogicFormDialog
              question={editorCtx.showConditonalLogicForm}
              close={() => editorCtx.setShowConditionalLogicForm(null)}
            />
          </div>
        </div>
        <AddQuestionButtonsSidebar
          form={form}
          canAddQuestions={canAddQuestions}
          setShowNewQuestionForm={editorCtx.setShowNewQuestionForm}
        />
      </div>
      <MyDialog isOpen={showPreview} cancel={closePreviewModal} addCloseButton>
        {!!form && (
          <PreparationFormPreview close={closePreviewModal} form={form} />
        )}
      </MyDialog>
    </>
  );
};

const PreparationFormPreview = (props: {
  close: () => void;
  form: IpisForm.ShellType;
}) => {
  const formResponse = useMutableQuery({
    queryKey: ["previewPreparationForm", props.form.id],
    queryFn: async () => {
      return {
        success: true,
        form: props.form,
        answers: [],
        formIsAnswered: false,
      } as Awaited<
        ReturnType<PreparationFormApiClient["getPreparationFormForCustomer"]>
      >;
    },
  });

  return (
    <PreparationFormContext.Provider value={{ formResponse, preview: true }}>
      <div className="grid h-full grid-cols-1 grid-rows-1 p-4">
        <div className="h-full w-full">
          <PreChecklistComponent
            formResponse={{
              success: true,
              form: props.form,
              answers: [],
              formIsAnswered: false,
            }}
            workOrder={
              {
                requiresMaterialToBeReviewed: true,
                startDate: new Date(),
                endDate: new Date(),
                endCustomer: {
                  name: "John Doe",
                },
              } as WorkOrder.CustomerType
            }
            /* checklistProps={{
          byPassValidation: true,
        }} */
            onFinished={props.close}
          />
        </div>
      </div>
    </PreparationFormContext.Provider>
  );
};

export default FormEditor;
