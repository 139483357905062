import { useContext } from "react";
import Dropdown, {
  DropdownProps,
} from "../../../../components/common/dropdown/Dropdown";
import ClientUtils from "./../../../../utils/ClientUtils";

import AppErrorIcon from "../../../icons/AppErrorIcon";
import { HandymanWorkOrderFileContext } from "../../files/DecoupledWorkOrderFileContextProvider";

type Props = Omit<DropdownProps, "title" | "children" | "buttonClassName">;

const HandymanConditionalFileErrorDropdown = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderFileContext);

  if (!ctx.isError) {
    return <></>;
  }

  return (
    <Dropdown
      {...props}
      title={() => (
        <>
          <AppErrorIcon className="text-red-600" size={24} />
          <span>Visa fel för hämtning av filer</span>
        </>
      )}
      buttonClassName="flex gap-2 items-center p-2"
      className={ClientUtils.twClassNames("pl-4", props.className)}
      contentClassName="p-2 w-[500px] max-w-[80vw] flex flex-col gap-2"
    >
      {ctx.relatedToWorkOrderFileRes.isError && (
        <ErrorItem error="Det gick inte att hämta filerna för material och faktura" />
      )}
      {ctx.workOrderFileRes.query.isError && (
        <ErrorItem error="Det gick inte att hämta filerna för arbetsorder eller produktpaket" />
      )}
    </Dropdown>
  );
};

const ErrorItem = (props: { error: string }) => {
  return (
    <span className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-2 text-sm">
      <AppErrorIcon className="text-red-600" size={20} />
      <span>{props.error}</span>
    </span>
  );
};

export default HandymanConditionalFileErrorDropdown;
