import { DateHelper } from "@eljouren/utils";
import { motion } from "framer-motion";
import { useContext, useRef, useState } from "react";
import { useOnEscapeClick } from "../../hooks/keyboard-event-hooks";
import { GlobalContext } from "../../top-level-contexts";
import ClientUtils from "./../../utils/ClientUtils";

import { CheckFilePermissionsCallback } from "../../types/file-permissions";
import { AppButton } from "../common/buttons/AppButton";
import AppArrowIcon from "../icons/AppArrowIcon";
import AppExternalLinkIcon from "../icons/AppExternalLinkIcon";
import AppTrashIcon from "../icons/AppTrashIcon";
import FileContent from "./FileContent";
import { IpisFileWithAltText } from "./SelectableFileGrid";
import IpisFileRelationDiv from "../work-order/handyman/files/IpisFileRelationFieldset";
import AppCaretIcon from "../icons/AppCaretIcon";

interface Props {
  className?: string;
  file: IpisFileWithAltText;
  disableDelete?: boolean;
  onDelete?(): void;
  onShowAll(): void;
  customDimensions?: boolean;
  filePermissionCallback: CheckFilePermissionsCallback;
  allFiles: IpisFileWithAltText[];
  selectImage: (image: IpisFileWithAltText) => void;
}

/* 
  	Remember to add onOutsideClick and onEscapeClick
  
  */
const SelectedFileGridItem = (props: Props) => {
  const isNonHeicImage =
    props.file.mimeType.startsWith("image") &&
    !props.file.mimeType.includes("heic") &&
    !props.file.mimeType.includes("heif");
  const authCtx = useContext(GlobalContext);
  const showUploadedByIcon = authCtx.signInState.signedInAs !== "customer";
  const [isExpanded, setIsExpanded] = useState(false);

  const ref = useRef<HTMLElement | null>(null);

  useOnEscapeClick(() => {
    props.onShowAll?.();
  });
  /*  useOnOutsideClick(ref, () => {
    props.onShowAll?.();
  }); */

  function getFileName(file: IpisFileWithAltText) {
    return file.nameWithExtension;
  }

  const showDeleteButton =
    !props.disableDelete &&
    !!props.onDelete &&
    props.filePermissionCallback(props.file).canDelete;

  function selectImages<T>(
    arr: T[],
    index: number
  ): {
    minIndex: number;
    maxIndex: number;
    splicedImages: T[];
  } {
    if (arr.length < 5) {
      return {
        minIndex: 0,
        maxIndex: arr.length - 1,
        splicedImages: arr,
      };
    }

    const baseMinIndex = index - 2;
    const baseMaxIndex = index + 2;
    const ceiling = arr.length - 1;

    let minIndexSubtraction = 0;
    let maxIndexAddition = 0;

    if (baseMinIndex < 0) {
      maxIndexAddition = Math.abs(baseMinIndex);
    }

    if (baseMaxIndex > ceiling) {
      minIndexSubtraction = baseMaxIndex - ceiling;
    }

    const minIndex = Math.max(baseMinIndex - minIndexSubtraction, 0);
    const maxIndex = Math.min(baseMaxIndex + maxIndexAddition, ceiling);
    const splicedImages = arr.slice(minIndex, maxIndex + 1);
    return {
      minIndex,
      maxIndex,
      splicedImages,
    };
  }

  const index = props.allFiles.findIndex(
    (file) => file.guid === props.file.guid
  );
  const { minIndex, maxIndex, splicedImages } = selectImages(
    props.allFiles,
    index
  );
  const hasImagesBeforeSubsection = minIndex > 0;
  const hasImagesAfterSubsection = maxIndex < props.allFiles.length - 1;
  const hasImagesBeforeAndAfterSubsection =
    hasImagesBeforeSubsection && hasImagesAfterSubsection;
  const hasImagesBeforeCurrentIndex = index > 0;
  const hasImagesAfterCurrentIndex = index < props.allFiles.length - 1;

  function getGridTemplateColumns(): string {
    if (hasImagesBeforeAndAfterSubsection) {
      return `0.125fr repeat(3, minmax(0, 0.25fr)) 0.125fr`;
    }

    if (hasImagesBeforeSubsection) {
      return `0.11fr repeat(4, minmax(0, 0.23fr))`;
    }

    if (hasImagesAfterSubsection) {
      return `repeat(4, minmax(0, 0.23fr)) 0.11fr`;
    }

    return `repeat(${splicedImages.length}, minmax(0,0.2fr))`;
  }

  function goToPreviousImage() {
    if (index > 0) {
      props.selectImage(props.allFiles[index - 1]);
    }
  }

  function goToNextImage() {
    if (index < props.allFiles.length - 1) {
      props.selectImage(props.allFiles[index + 1]);
    }
  }

  function toggleExpanded() {
    setIsExpanded((prev) => !prev);
  }

  return (
    <section
      id="selected-file"
      ref={ref}
      key={props.file.src}
      className={ClientUtils.twClassNames(
        props.className,
        "children-w-[1500px] relative grid min-h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto] overflow-y-auto overflow-x-hidden bg-off-white",
        !props.customDimensions && "h-full w-full",
        !isNonHeicImage && "border"
      )}
    >
      {/* <aside className="absolute right-0 h-full w-48 max-w-full border-4"></aside> */}

      <motion.header
        className={ClientUtils.twClassNames(
          "relative mx-auto flex w-full flex-col bg-gradient-to-br from-main-bg-light to-main-bg-dark px-2 py-3 text-white lg:p-4"
        )}
      >
        <motion.span
          className={ClientUtils.twClassNames(
            "w-full items-center text-base font-bold",
            !isExpanded && "truncate",
            isExpanded && "break-words"
          )}
        >
          {getFileName(props.file)}
        </motion.span>

        <span className="col-span-2 col-start-2 row-start-2 flex items-center justify-end">
          {props.file.createdDate && (
            <span className="mr-auto text-sm italic text-white text-opacity-80">
              {new DateHelper(props.file.createdDate).dateInputFormat}
            </span>
          )}
          <button
            onClick={toggleExpanded}
            className="flex items-center text-sm"
          >
            {isExpanded && <span>Visa mindre</span>}
            {!isExpanded && <span>Visa mer</span>}
            <AppCaretIcon
              direction="down"
              size={20}
              className={ClientUtils.classNames()}
            />
          </button>
        </span>
        {isExpanded && (
          <div className="absolute left-0 top-full z-10 flex w-full flex-col gap-2 bg-black/80 p-4 text-sm">
            <section>
              <header>
                <h3>Kopplingar</h3>
              </header>
              <main>
                {showUploadedByIcon && (
                  <IpisFileRelationDiv file={props.file} />
                )}
              </main>
            </section>
            <section>
              <header>
                <h3>Beskrivning</h3>
              </header>
              <main>
                <motion.p className="row-start-2">
                  {props.file.description ?? "Ingen beskrivning"}
                </motion.p>
              </main>
            </section>
          </div>
        )}
      </motion.header>

      <motion.main
        className={ClientUtils.twClassNames(
          "mx-auto mb-auto grid h-full w-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto] justify-center gap-2 pt-2"
        )}
      >
        <motion.ul
          style={{
            gridTemplateColumns: getGridTemplateColumns(),
          }}
          className={ClientUtils.classNames(
            "grid h-20 grid-rows-1 items-center justify-center gap-2 overflow-hidden ",
            !hasImagesAfterSubsection && "pr-2",
            !hasImagesBeforeSubsection && "pl-2"
          )}
        >
          {splicedImages.map((image, i) => {
            const isSelected = image.guid === props.file.guid;
            const isFirst = i === 0;
            const isFirstWithImagesBefore =
              isFirst && hasImagesBeforeSubsection;
            const isLast = i === splicedImages.length - 1;
            const isLastWithImagesAfter = isLast && hasImagesAfterSubsection;
            return (
              <motion.li
                /* initial={{
                  opacity: 0,
                }} */
                animate={{
                  opacity: isSelected ? 1 : 0.6,
                }}
                /* layoutId={image.guid} */
                key={image.guid}
                className={ClientUtils.classNames(
                  "cursor-pointer overflow-hidden rounded border shadow-lg",
                  isSelected && "h-full border-brand-blue-600 ",
                  !isSelected &&
                    "h-[90%] border-transparent opacity-60 hover:opacity-90",
                  isFirstWithImagesBefore && "rounded-l-none",
                  isLastWithImagesAfter && "rounded-r-none"
                )}
                onClick={() => {
                  props.selectImage(image);
                }}
              >
                <FileContent
                  file={image}
                  className={ClientUtils.classNames(
                    isFirstWithImagesBefore && "object-right",
                    isLastWithImagesAfter && "object-left"
                  )}
                />
              </motion.li>
            );
          })}
        </motion.ul>
        <figure className="relative mx-auto grid h-full w-full grid-rows-1 bg-main-bg-dark/70">
          <button
            className="absolute top-0 flex h-full items-center bg-white/20 px-2 opacity-50 hover:opacity-100 disabled:opacity-0 disabled:hover:opacity-0"
            disabled={!hasImagesBeforeCurrentIndex}
            onClick={goToPreviousImage}
          >
            <AppArrowIcon direction="left" size={45} />
          </button>
          <FileContent contain file={props.file} />
          <button
            className="absolute right-0 top-0 flex h-full items-center bg-white/20 px-2 opacity-50 hover:opacity-100 disabled:opacity-0 disabled:hover:opacity-0"
            disabled={!hasImagesAfterCurrentIndex}
            onClick={goToNextImage}
          >
            <AppArrowIcon direction="right" size={45} />
          </button>
        </figure>
        <div className="flex items-center justify-end gap-2 p-2">
          {showDeleteButton && (
            <button
              className="flex h-12 w-12 items-center justify-center gap-2 rounded-full bg-red-600 text-white sm:grow sm:rounded"
              onClick={props.onDelete}
            >
              <AppTrashIcon size={20} className="text-whit flex items-center" />
              <span className="sr-only sm:not-sr-only">Ta bort fil</span>
            </button>
          )}
          <a
            className="flex h-12 w-12 items-center justify-center gap-2 rounded-full border sm:grow sm:rounded "
            target={"_blank"}
            rel="noreferrer"
            href={props.file.src}
          >
            <AppExternalLinkIcon size={20} />
            <span className="sr-only sm:not-sr-only">Öppna i ny flik</span>
          </a>
        </div>
      </motion.main>
      <footer className="p-2">
        <AppButton className="w-full" onClick={props.onShowAll}>
          <AppArrowIcon
            direction="left"
            size={30}
            className="flex items-center text-white"
          />
          <span>Visa alla bilder</span>
        </AppButton>
      </footer>
    </section>
  );
};

export default SelectedFileGridItem;
