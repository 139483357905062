import { IpisFormBoundaries, IpisFormElement } from "@eljouren/domain";
import { UUID } from "@eljouren/utils";
import ChecklistDecoupledMultipleChoiceQuestion from "../../../checklist-question-types/multiple-choice/ChecklistDecoupledMultipleChoiceQuestion";
import WizardForm from "../../../wizard-form/WizardForm";
import { useWizardForm } from "../../../wizard-form/use-wizard-form";
import InputBuilderLayout from "../InputBuilderLayout";
import {
  WizardDescriptionField,
  WizardFormInference,
  WizardHelperTextField,
  WizardNoteField,
  WizardRequiredField,
  WizardTitleField,
} from "../common-wizard-fields";

const page1Config = WizardFormInference.inferQuestionArray([
  WizardTitleField,
  WizardDescriptionField,
  WizardHelperTextField,
  WizardNoteField,
  WizardRequiredField,
]);
const page2Config = WizardFormInference.inferQuestionArray([
  {
    id: "options",
    clientSideId: null,
    required: true,
    walkthroughHeading: "Repeater testing",
    walkthroughSubheading: "Repeater testing",
    typeOfQuestion: "repeater",
    type: "question",
    title: "Vilka alternativ vill du tillåta användaren att välja mellan?",
    repeaterGroup: {
      name: "Alternativ",
      items: [
        {
          id: "value",
          clientSideId: null,
          typeOfQuestion: "text",
          required: true,
          type: "repeater-item",
          placeholder: "Value",
          minLength: IpisFormBoundaries.multipleChoice.option.minLength,
          maxLength: IpisFormBoundaries.multipleChoice.option.maxLength,
        },
      ],
    },
  },
  {
    id: "allowOther",
    clientSideId: null,
    typeOfQuestion: "yes/no",
    required: true,
    type: "question",
    renderAs: "toggle",
    title:
      "Vill du tillåta användaren att välja ett 'Annat'-alternativ, som låter de skriva in ett eget alternativ när inget av dem förvalda passar in?",
    walkthroughHeading: "-",
    walkthroughSubheading: "-",
  },
  {
    id: "renderAs",
    clientSideId: null,
    typeOfQuestion: "multiple-choice",
    type: "question",
    allowOther: false,
    required: true,
    title: "Hur vill du visa alternativen?",
    walkthroughHeading: "Render as",
    walkthroughSubheading: "Render as",
    renderAs: "always-visible",
    multiple: false,
    options: [
      {
        id: "always-visible",
        clientSideId: null,
        value: "Knappar",
      },
      {
        id: "dropdown",
        clientSideId: null,
        value: "Dropdown-lista",
      },
    ],
  },
]);

const configs = [page1Config, page2Config];

type FormValues = WizardFormInference.FormValues<typeof configs>;
type SubmitValues = Omit<IpisFormElement.MultipleChoiceType, "id">;
interface Props {
  className?: string;
  defaultValues?: SubmitValues;
  onSubmit: (data: SubmitValues) => void;
  closeForm: () => void;
}

const ChecklistMultipleChoiceInputBuilder = (props: Props) => {
  function submitValuesToFormValues(
    values: SubmitValues | undefined
  ): Partial<FormValues> {
    if (!values) {
      return {};
    }

    /* 
      This is incredibly confusing
    */
    const repeaterItems = values.options.reduce((acc, option) => {
      acc[option.id] = {
        value: {
          value: option.value,
        },
      };
      return acc;
    }, {} as Record<string, { value: { value: string } }>);

    const formValues: FormValues = {
      title: { value: values.title },
      richTextBody: {
        value: values.description ?? "",
      },
      required: { value: !!values.required },
      helperText: {
        value: values.tooltip ?? "",
      },
      note: { value: values.note ?? "" },
      options: {
        repeaterItems,
      },
      allowOther: { value: values.allowOther },
      renderAs: {
        value:
          values.renderAs === "always-visible" ? "Knappar" : "Dropdown-lista",
      },
    };

    return formValues;
  }

  const wizardHook = useWizardForm({
    formId: "checklist-text-input-builder",
    configs,
    onSubmit: onSubmit,
    defaultValues: {
      renderAs: { value: "Knappar" },
      ...submitValuesToFormValues(props.defaultValues),
    },
  });

  /* WizardTitleField,
    WizardDescriptionField,
    WizardPlaceHolderField,
    WizardHelperTextField,
    WizardNoteField,
    WizardMinLengthField,
    WizardMaxLengthField, */

  /* wizardHook.form.watch(() => {
    console.log("Update in watch!");
  });
  useEffect(() => {
    console.log("Update!");
  }); */

  function getOptionsFromFormValues(formValues: FormValues) {
    const repeaterItems = formValues.options?.repeaterItems ?? {};
    const groups = Object.values(repeaterItems);
    /* 
      This is incredibly confusing!!!
    */
    const options: IpisFormElement.MultipleChoiceType["options"] = groups
      .filter((group) => !!group.value?.value)
      .map((group) => {
        const id = UUID.generate().value;
        const option: IpisFormElement.MultipleChoiceType["options"][0] = {
          id,
          clientSideId: id,
          value: group.value?.value?.toString() ?? "",
        };
        return option;
      });

    return options;
  }

  const options = getOptionsFromFormValues(wizardHook.form.watch());

  function buildElement(
    values: FormValues
  ): IpisFormElement.MultipleChoiceType {
    const id = UUID.generate().value;
    const question: IpisFormElement.MultipleChoiceType = {
      id: id,
      clientSideId: id,
      typeOfQuestion: "multiple-choice",
      required: values.required?.value ?? false,
      allowOther: values.allowOther?.value ?? false,
      renderAs:
        values.renderAs?.value === "Dropdown-lista"
          ? "dropdown"
          : "always-visible",
      multiple: false,
      type: "question",
      title: values.title?.value ?? "",
      description: values.richTextBody?.value,
      tooltip: values.helperText?.value,
      note: values.note?.value,
      options,
    };

    return question;
  }

  const question = buildElement(wizardHook.form.watch());

  const atLeastOneQuestionAnswered = Object.values(
    wizardHook.form.getValues()
  ).some((value) => !!value);

  function onSubmit(data: FormValues) {
    const element = buildElement(data);
    props.onSubmit(element);
  }

  return (
    <InputBuilderLayout
      closeForm={props.closeForm}
      heading="Skapa fält för flervalsalternativ"
      description="Skapa ett fält där användaren kan välja ett eller flera alternativ"
      atLeastOneQuestionAnswered={atLeastOneQuestionAnswered}
      Form={<WizardForm hookReturn={wizardHook} />}
      PreviewComponent={
        <ChecklistDecoupledMultipleChoiceQuestion
          disabled
          form={wizardHook.form}
          question={question}
        />
      }
    />
  );
};

export default ChecklistMultipleChoiceInputBuilder;
