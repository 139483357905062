import { IpisFileV2 } from "@eljouren/file-schemas";
import IClientWorkOrderFileRepoV2 from "../../repos/interfaces/IClientWorkOrderFileRepoV2";
import {
  ClientSideSuccessfulGetPreparationFormOutput,
  TrpcSuccessfulGetPreparationFormOutput,
} from "./preparation-form-api-client-types";
import {
  FormElementAnswer,
  IpisFormElementIsolatedAnswer,
} from "@eljouren/domain";

interface Props {
  fileServerApiClient: IClientWorkOrderFileRepoV2;
}

/* 
	Uses the presigned URLs returned from the server to fetch the images and attach them to the answers.
	Isolated in this class for easier testing and to be able to reuse the same logic in different
	api clients.
*/
export default class PreparationFormFileAttacher {
  constructor(private readonly props: Props) {}

  /* 
	The returntype is to alert the caller of the error without throwing,
	so that the caller can handle the error by notifying the backend that the
	image fetching step failed.
  */
  public async attach(input: TrpcSuccessfulGetPreparationFormOutput): Promise<
    | {
        success: true;
        output: ClientSideSuccessfulGetPreparationFormOutput;
      }
    | {
        success: false;
        error: unknown;
      }
  > {
    try {
      const presigned = input.presigned;
      const serverSideAnswers = input.answers;
      let images: IpisFileV2.Type[] = [];

      if (presigned) {
        images = await this.props.fileServerApiClient.getFilesWithPresignedUrl({
          url: presigned.url,
        });
      }

      const clientSide: IpisFormElementIsolatedAnswer.ClientSideType[] =
        serverSideAnswers.map((a) => {
          /* 
			If the answer isn't of type image-group, there are no images 
			to attach.
		*/
          if (a.type !== "image-group") {
            return a;
          }

          const imagesRelatedToCurrentAnswer = images
            .filter((i) => a.id === i.recordId)
            .map((el) => {
              const img: FormElementAnswer.PostUploadImageType = {
                state: "postupload",
                ...el,
              };
              return img;
            });

          const answer: IpisFormElementIsolatedAnswer.ClientSideImageGroupType =
            {
              ...a,
              answer: {
                imageUploadComment: a.answer.imageUploadComment,
                images: imagesRelatedToCurrentAnswer,
              },
            };

          return answer;
        });

      return {
        success: true,
        output: {
          ...input,
          answers: clientSide,
        },
      };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }
}
