import { FormElementAnswer } from "@eljouren/domain";
import { FileMetaInput, IpisFile, IpisFileV2 } from "@eljouren/file-schemas";
import { UUID } from "@eljouren/utils";
import { z } from "@ipis/centralized-zod";
import { useCallback, useEffect, useState } from "react";

export const FileUploadNewFileSchema = z.object({
  state: z.literal("preupload"),
  native: z.instanceof(File),
  guid: z.string(),
  dataUrl: z.string(),
  meta: FileMetaInput.Schema,
});
export type FileUploadNewFile = z.infer<typeof FileUploadNewFileSchema>;

export const FileUploadExistingFileSchema = IpisFileV2.Schema.extend({
  state: z.literal("postupload"),
});

export type FileUploadExistingFile = z.infer<
  typeof FileUploadExistingFileSchema
>;

export const FileUploadHookFileSchema = z.union([
  FileUploadNewFileSchema,
  FileUploadExistingFileSchema,
]);
export type FileUploadHookFile = z.infer<typeof FileUploadHookFileSchema>;

/* 
  Use "onlyNew" when previous files will never be provided
*/
export type FileUploadFilter = "onlyNew" | "all";
export type FileUploadFilesFromFilter<F extends FileUploadFilter> =
  F extends "onlyNew" ? FileUploadNewFile[] : FileUploadHookFile[];

type Allow = "images" | "all";

export type UseFileUploadProps<F extends FileUploadFilter> = {
  filter?: F;
  allow?: Allow;
  maxSize?: {
    inBytes: number;
    stringRepresentation: string;
  };
  maxFileNameLength: number;
  ref: React.RefObject<HTMLInputElement | null>;
  currentFiles: FileUploadFilesFromFilter<F>;
  onChange?: (files: FileUploadFilesFromFilter<F>) => any;
  createName?: (file: File) => string;
};

type ProcessNewFilesReturn<F extends FileUploadFilter> =
  | {
      state: "success";
      allFiles: FileUploadFilesFromFilter<F>;
    }
  | {
      state: "nothingToProcess";
    }
  | {
      state: "error";
    };

type UseFileUploadReturn<F extends FileUploadFilter> = {
  trigger: () => void;
  removeFile: (file: FileUploadHookFile) => Promise<void>;
  hasFiles: boolean;
  maxSizeStr: string;
  currentFiles: FileUploadFilesFromFilter<F>;
};

/* 
  Not sure if this is needed anymore as we use presigned urls now
*/
async function toDataUrl(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;

      let dataUrl: string;
      if (file.type) {
        dataUrl = result;
      } else {
        if (file.name.trim().toLowerCase().endsWith(".heic")) {
          dataUrl = result.replace(
            "data:application/octet-stream",
            "data:image/heic"
          );
        } else {
          dataUrl = result;
        }
      }

      resolve(dataUrl);
    };
    reader.onerror = reject;
  });
}

async function processNewFiles<F extends FileUploadFilter>(
  props: Pick<
    UseFileUploadProps<F>,
    "ref" | "maxFileNameLength" | "createName"
  > & {
    maxSizeInBytes: number;
    maxSizeStr: string;
    currentFiles: FileUploadHookFile[];
  }
): Promise<ProcessNewFilesReturn<F>> {
  try {
    const ref = props.ref;
    function resetInput() {
      if (!ref.current) {
        return;
      }
      ref.current.value = "";
    }

    const files = ref.current?.files;
    if (!files?.length) {
      resetInput();
      return {
        state: "nothingToProcess",
      };
    }

    const filesArray = Array.from(files);

    const tooLargeFiles: File[] = [];
    const filesWithinSizeLimit: File[] = [];

    filesArray.forEach((file) => {
      if (file.size > props.maxSizeInBytes) {
        tooLargeFiles.push(file);
      } else {
        filesWithinSizeLimit.push(file);
      }
    });

    if (tooLargeFiles.length > 0) {
      let title: string;
      if (filesArray.length === 1) {
        title = `1 fil är större än maxstorleken på ${props.maxSizeStr}`;
      } else if (tooLargeFiles.length === 1) {
        title = `Filen är större än maxstorleken på ${props.maxSizeStr}`;
      } else {
        title = `${tooLargeFiles.length} filer är över maxstorleken på ${props.maxSizeStr}`;
      }

      window.ipisModal.alert({
        title,
        prompt: `Följande filer är för stora: ${tooLargeFiles
          .map((file) => file.name)
          .join(", ")}`,
        typeOfAlert: "error",
      });
    }

    if (filesWithinSizeLimit.length === 0) {
      resetInput();
      return {
        state: "nothingToProcess",
      };
    }

    const customFiles: FileUploadNewFile[] = await Promise.all(
      filesWithinSizeLimit.map(async (file) => {
        let name: string;
        if (props.createName) {
          name = props.createName(file);
        } else {
          const split = file.name.split(".");
          name = split
            .slice(0, -1)
            .join(".")
            .substring(0, props.maxFileNameLength);
        }
        const meta: FileMetaInput.Type = {
          description: "",
          name: name,
        };
        return {
          state: "preupload",
          native: file,
          dataUrl: await toDataUrl(file),
          guid: UUID.generate().value,
          meta,
        };
      })
    );

    const previous = props.currentFiles;
    const duplicateMap: Record<string, true> = Object.fromEntries(
      previous
        .filter(
          (el): el is FormElementAnswer.PreUploadImageType =>
            el.state === "preupload"
        )
        .map((file) => [file.dataUrl, true])
    );

    const duplicates: FileUploadNewFile[] = [];
    const uniqueFiles: FileUploadNewFile[] = [];

    customFiles.forEach((file) => {
      if (duplicateMap[file.dataUrl]) {
        duplicates.push(file);
      } else {
        uniqueFiles.push(file);
        duplicateMap[file.dataUrl] = true;
      }
    });

    if (duplicates.length > 0) {
      window.ipisModal.alert({
        title: "Filer med samma innehåll hittades",
        prompt: `Dubletterna kommer att ignoreras`,
        typeOfAlert: "notification",
      });
    }

    if (uniqueFiles.length === 0) {
      resetInput();
      return {
        state: "nothingToProcess",
      };
    }

    resetInput();
    return {
      state: "success",
      allFiles: [
        ...props.currentFiles,
        ...uniqueFiles,
      ] as FileUploadFilesFromFilter<F>,
    };
  } catch (error) {
    console.log("Error reading files", error);
    window.ipisModal.alert({
      title: "Något gick fel",
      prompt:
        "Det gick inte att läsa filerna just nu. Vänligen försök igen senare.",
      typeOfAlert: "error",
    });
    return {
      state: "error",
    };
  }
}

export function useFileUpload<F extends FileUploadFilter>(
  props: UseFileUploadProps<F>
): UseFileUploadReturn<F> {
  /* 
	   Defaults to 10mb with some leeway
	*/
  const maxSizeInBytes = props.maxSize?.inBytes ?? 11 * Math.pow(10, 6);
  const maxSizeStr = props.maxSize?.stringRepresentation ?? "10 MB";

  const [currentFiles, setCurrentFiles] = useState<FileUploadHookFile[]>(
    props.currentFiles
  );

  const { onChange, ref, maxFileNameLength } = props;
  const update = useCallback(async () => {
    const result: ProcessNewFilesReturn<F> = await processNewFiles({
      maxSizeInBytes,
      maxSizeStr,
      currentFiles,
      ref,
      maxFileNameLength,
      createName: props.createName,
    });
    if (result.state === "success") {
      onChange?.(result.allFiles);
    }
  }, [
    onChange,
    maxFileNameLength,
    maxSizeInBytes,
    maxSizeStr,
    currentFiles,
    ref,
    props.createName,
  ]);

  useEffect(() => {
    setCurrentFiles(props.currentFiles);
  }, [props.currentFiles]);

  useEffect(() => {
    const listener = () => {
      update();
    };

    const element = props.ref.current;
    if (!element) {
      return;
    }

    element.addEventListener("change", listener);

    return () => {
      element.removeEventListener("change", listener);
    };
  }, [props.onChange, props.ref, update]);

  function trigger() {
    props.ref.current?.click();
  }

  async function removeFile(file: FileUploadHookFile) {
    if (file.state === "postupload") {
      throw new Error(
        "Cannot remove postupload files yet - need to implement deletion on the server side."
      );
    }

    const confirm = await window.ipisModal.confirm({
      title: "Ta bort bild",
      prompt: "Är du säker på att du vill ta bort bilden?",
      confirmLabel: "Ja, ta bort bilden",
      rejectLabel: "Nej, behåll bilden",
    });

    if (!confirm) {
      return;
    }

    const filtered = currentFiles.filter(
      (f) => f.guid !== file.guid
    ) as FileUploadFilesFromFilter<F>;
    props.onChange?.(filtered);
  }

  return {
    trigger,
    removeFile,
    hasFiles: currentFiles?.length > 0,
    maxSizeStr,
    currentFiles: currentFiles as FileUploadFilesFromFilter<F>,
  };
}

export function useFileInputAccept(props: {
  allow?: Allow;
  removeHeic?: boolean;
}): string {
  const onlyImages = props.allow === "images";
  let accept: string;
  if (onlyImages) {
    if (props.removeHeic) {
      accept = IpisFile.NonHeicImages.options.map((opt) => `.${opt}`).join(",");
    } else {
      accept = IpisFile.ImageExtensions.options
        .map((opt) => `.${opt}`)
        .join(",");
    }
  } else {
    if (props.removeHeic) {
      accept = IpisFile.AllowedExtensionsWithoutHeic.options
        .map((opt) => `.${opt}`)
        .join(",");
    } else {
      accept = IpisFile.AllowedExtensions.options
        .map((opt) => `.${opt}`)
        .join(",");
    }
  }

  return accept;
}
