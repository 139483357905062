import { IpisFormElement, IpisFormPage, IpisForm } from "@eljouren/domain";
import {
  IpisFormAnswerValidator,
  IpisFormClientSchemaBuilder,
} from "@eljouren/domain-utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import {
  DefaultValues,
  FieldErrors,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { BaseEditor, createEditor } from "slate";
import { ReactEditor, withReact } from "slate-react";
import { WizardFormInference } from "../builder/input-builders/common-wizard-fields";
import { WizardFormConfig } from "./wizard-form-types";

export type UseWizardFormArgs<E extends WizardFormInference.Config> = {
  formId: string;
  configs: E;
  onSubmit: (values: WizardFormInference.FormValues<E>) => void;
  defaultValues?: DefaultValues<WizardFormInference.FormValues<E>>;
};

export type UseWizardFormReturn<E extends WizardFormInference.Config> = {
  pageIndex: number;
  form: UseFormReturn<WizardFormInference.FormValues<E>>;
  richTextEditors: Record<string, BaseEditor & ReactEditor>;
  canGoForward: () => boolean;
  canGoBack: () => boolean;
  goBack: () => void;
  hasGoneThroughWalkthrough: boolean;
  onSubmitListener: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  currentConfig: WizardFormConfig;
  validatedChecklistDo: IpisFormAnswerValidator;
  fauxChecklist: IpisForm.ShellType;
};

export function useWizardForm<E extends WizardFormInference.Config>(
  args: UseWizardFormArgs<E>
): UseWizardFormReturn<E> {
  const fauxChecklist: IpisForm.ShellType = {
    id: args.formId + "-faux-checklist",
    clientSideId: null,
    name: args.formId + "-faux-checklist",
    type: "internal-form",
    isInactive: false,
    hasAnswers: false,
    pages: args.configs.map((config, i) => {
      const element: IpisFormElement.Type[] = config;
      const page: IpisFormPage.WithoutAnswersType = {
        id: `${args.formId}-page-${i}`,
        clientSideId: null,
        pageTitleShorthand: `Page ${i + 1}`,
        pageTitle: `Page ${i + 1}`,
        elements: element,
      };
      return page;
    }),
  };

  const [pageIndex, setPageIndex] = useState(0);
  const form = useForm<WizardFormInference.FormValues<E>>({
    defaultValues: args.defaultValues,
    resolver: (values, context, options) => {
      const validatedChecklist = new IpisFormAnswerValidator({
        form: fauxChecklist,
        currentFormValues: values,
      });
      const page = fauxChecklist.pages[pageIndex];
      const schema = new IpisFormClientSchemaBuilder({
        validated: validatedChecklist,
      })
        .forPage(page)
        .passthrough();
      return zodResolver(schema)(values, context, options);
    },
  });

  /* const [hasGoneThroughWalkthrough, setHasGoneThroughWalkthrough] = useSsState(
    `${props.formId}-has-gone-through-walkthrough-test`,
    false
  ); */

  const hasGoneThroughWalkthrough = true;

  const richTextIds = args.configs
    .flat()
    .filter((element) => element.typeOfQuestion === "rich-text")
    .map((element) => element.id);

  const ids = richTextIds.join(",");
  const richTextEditors = useMemo<Record<string, BaseEditor & ReactEditor>>(
    () => {
      return richTextIds.reduce((acc, id) => {
        acc[id] = withReact(createEditor());
        return acc;
      }, {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ids]
  );

  const currentConfig = args.configs[pageIndex];

  const hasMoreThanOnePage = args.configs.length > 1;

  function canGoForward() {
    return hasMoreThanOnePage && pageIndex < args.configs.length - 1;
  }

  function canGoBack() {
    return hasMoreThanOnePage && pageIndex > 0;
  }

  function goBack() {
    if (canGoBack()) {
      setPageIndex((oldIndex) => oldIndex - 1);
    }
  }

  function nativeOnSubmit(values: WizardFormInference.FormValues<E>) {
    if (canGoForward()) {
      setPageIndex((oldIndex) => oldIndex + 1);
    } else {
      args.onSubmit(values);
    }
  }

  function onError(errors: FieldErrors<WizardFormInference.FormValues<E>>) {
    console.log({ errors });
  }

  const onSubmitListener = form.handleSubmit(nativeOnSubmit, onError);

  const validatedChecklistDo = new IpisFormAnswerValidator({
    form: fauxChecklist,
    currentFormValues: form.getValues(),
  });

  return {
    fauxChecklist,
    pageIndex,
    form,
    richTextEditors,
    canGoForward,
    canGoBack,
    onSubmitListener,
    goBack,
    hasGoneThroughWalkthrough,
    currentConfig,
    validatedChecklistDo,
  };
}
