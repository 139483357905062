import { IpisFileV2 } from "@eljouren/file-schemas";
import { THandymanFileFilters } from "../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import {
  FileWithPreparationFormRelation,
  UseFileSortingOutput,
} from "./use-file-sorting";

type Args = {
  sortedFiles: UseFileSortingOutput;
};

type Output = {
  applyFilters: (filters: THandymanFileFilters) => {
    images: IpisFileV2.Type[];
    documents: IpisFileV2.Type[];
  };
};

export function useFileFiltering(args: Args): Output {
  function filterFile(
    filters: THandymanFileFilters,
    file: FileWithPreparationFormRelation
  ): boolean {
    if (file.isLinkedToPreparationsForm) {
      return filters.preparationFormFiles;
    }

    const relations = file.relations;
    return !!relations?.some((rel) => {
      switch (rel.type) {
        case "workOrder":
        case "case":
          return filters.workOrderFiles;
        case "workOrderLineItem":
          return filters.workOrderLineItemFiles;
        case "opportunityLineItem":
          return filters.opportunityLineItemFiles;
        case "productPackage":
          return filters.productPackageFiles;
        case "serviceContract":
          return filters.serviceContractFiles;
      }
      return false;
    });
  }

  function applyFilters(filters: THandymanFileFilters) {
    const images: IpisFileV2.Type[] = args.sortedFiles.allImages.filter(
      (file) => filterFile(filters, file)
    );
    const documents: IpisFileV2.Type[] = args.sortedFiles.allDocuments.filter(
      (file) => filterFile(filters, file)
    );

    return { images, documents };
  }

  return { applyFilters };
}
