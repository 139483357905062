import {
  IpisFormElementIsolatedAnswer,
  IpisForm,
  WorkOrder,
} from "@eljouren/domain";
import { IpisFormAnswerValidator } from "@eljouren/domain-utils";
import { IpisLoadingOverlay } from "@ipis/client-essentials";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

import { useApiClients } from "../../../../hooks/use-api-clients";
import { usePreparationFormContext } from "../../../../routes/customer/preparations-route/CustomerWorkOrderPreparationsRouteV2";
import ChecklistV2Component, {
  ChecklistV2Props,
} from "../ChecklistV2Component";
import PreChecklistPostSummary from "./PreChecklistPostSummary";
import PreChecklistPreview from "./PreChecklistPreview";
import PreparationFormApiClient from "../../../../_model/api-clients/preparation-form/PreparationFormApiClient";

/* type PrechecklistPage = "preview" | "checklist" | "post-summary"; */

type State =
  | {
      page: "preview" | "checklist";
    }
  | {
      page: "post-summary";
      currentFormVersion: IpisForm.ShellType;
      answers: IpisFormElementIsolatedAnswer.ClientSideType[];
    };

export type PreChecklistWorkOrder = WorkOrder.CustomerType;
export type Response = Awaited<
  ReturnType<PreparationFormApiClient["getPreparationFormForCustomer"]>
>;
export type SuccessfulResponse = Extract<Response, { success: true }>;

export type PrechecklistProps<T extends IpisForm.ShellType> = {
  formResponse: Response;
  workOrder: PreChecklistWorkOrder;
  checklistProps?: Omit<ChecklistV2Props<T>, "checklist" | "onSubmit">;
  onFinished?: () => void;
};

interface Props<T extends IpisForm.ShellType> extends PrechecklistProps<T> {
  className?: string;
}

const PreChecklistComponent = <T extends IpisForm.ShellType>(
  props: Props<T>
) => {
  const res = props.formResponse;

  if (res.success === true) {
    return <OnSuccess {...props} successFulResponse={res} />;
  }

  if (res.reason === "WORK_ORDER_ALREADY_COMPLETED") {
    return (
      <section className="mx-auto flex w-full max-w-screen-md flex-col p-8">
        <header>
          <h2 className="text-2xl font-bold text-dark-950">
            Arbetsordern är redan avslutad
          </h2>
        </header>
        <p>Arbetsordern är redan avslutad och kan inte längre redigeras.</p>
      </section>
    );
  }

  if (res.reason === "WORK_ORDER_IS_CANCELED") {
    return (
      <section className="mx-auto flex w-full max-w-screen-md flex-col p-8">
        <header>
          <h2 className="text-2xl font-bold text-dark-950">
            Arbetsordern är avbruten
          </h2>
        </header>
        <p>
          Arbetsordern är avbruten och kan inte längre redigeras. Kontakta
          kundtjänst för mer information.
        </p>
      </section>
    );
  }

  // Unknown
  return (
    <section className="mx-auto flex w-full max-w-screen-md flex-col p-8">
      <header>
        <h2 className="text-2xl font-bold text-dark-950">Något gick fel</h2>
      </header>
      <p>
        Något gick fel när vi skulle hämta formuläret. Försök igen eller
        kontakta kundtjänst.
      </p>
    </section>
  );
};

const OnSuccess = (
  props: Props<any> & {
    successFulResponse: SuccessfulResponse;
  }
) => {
  const ctx = usePreparationFormContext();
  const { ipisFormRepo, workOrderFileRepo } = useApiClients();
  const [state, setState] = useState<State>(initialState());

  function initialState(): State {
    if (props.successFulResponse.formIsAnswered) {
      return {
        page: "post-summary",
        currentFormVersion: props.successFulResponse.form,
        answers: props.successFulResponse.answers,
      };
    }

    return {
      page: "preview",
    };
  }

  function continueToChecklist() {
    setState({
      page: "checklist",
    });
  }

  const response = props.successFulResponse;

  useEffect(() => {
    if (response.formIsAnswered) {
      setState({
        page: "post-summary",
        currentFormVersion: response.form,
        answers: response.answers,
      });
    }
  }, [response]);

  async function onSubmit(validated: IpisFormAnswerValidator) {
    try {
      if (!validated.passesValidation()) {
        window.ipisModal.alert({
          title: "Svar saknas",
          prompt: "Du måste svara på alla frågor innan du kan fortsätta.",
          typeOfAlert: "error",
        });
        return;
      }
      const po = validated.getFullyValidated();
      const prepared = validated.prepareForSaving();

      if (ctx.preview) {
        window.ipisModal.alert({
          title: "Svaren validerades korrekt",
          prompt:
            "I en riktig situation skulle svaren nu skickas till servern.",
          typeOfAlert: "error",
        });
        props.onFinished?.();
        return;
      }

      await ctx.formResponse.mutate({
        callback: async () => {
          const res = await ipisFormRepo.submitPreparationForm({
            validatedForm: po,
            fileServerApiClient: workOrderFileRepo,
            ...prepared,
          });
          if (!res.serverSideResult.success) {
            if (res.serverSideResult.reason === "OUTDATED_FORM") {
              window.ipisModal.alert({
                title: "Formuläret har uppdaterats sen du började fylla i det.",
                prompt:
                  "Formuläret har uppdaterats sedan du började fylla i det. Vänligen ladda om sidan och försök igen.",
                typeOfAlert: "error",
              });
              return;
            }

            if (res.serverSideResult.reason === "INVALID_ANSWERS") {
              throw new Error("INVALID_ANSWERS");
            }

            throw new Error("UNKNOWN_ERROR");
          } else {
            if (res.imageUploadResult !== "success") {
              window.ipisModal.alert({
                title: "Något gick fel med bilduppladdningen",
                prompt:
                  "Dina svar är sparade, men bilderna kunde inte laddas upp. Vi kontaktar dig om vi behöver mer information.",
                typeOfAlert: "error",
              });
            }
          }
        },
      });
    } catch (e) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Okänt fel uppstod. Försök igen senare.",
        typeOfAlert: "error",
      });
    }
    /* setState({
      page: "post-summary",
      validatedChecklist: validated,
    }); */
  }

  const form = props.successFulResponse.form;

  return (
    <>
      <IpisLoadingOverlay isVisible={ctx.formResponse.isLoading} />
      <div className="h-full w-full overflow-auto">
        <AnimatePresence exitBeforeEnter>
          {state.page === "preview" && (
            <PreChecklistPreview
              key={"preview"}
              checklist={form}
              continueToChecklist={continueToChecklist}
              workOrder={props.workOrder}
            />
          )}
          {state.page === "checklist" && (
            <ChecklistV2Component
              key={"checklist"}
              checklist={form}
              onSubmit={onSubmit}
            />
          )}
          {state.page === "post-summary" && (
            <PreChecklistPostSummary
              key={"post-summary"}
              currentFormVersion={form}
              answers={state.answers}
              workOrder={props.workOrder}
              onFinished={props.onFinished}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default PreChecklistComponent;
