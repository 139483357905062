import { Checklist, WorkOrder } from "@eljouren/domain";
import { UseQueryWrapperResult } from "@ipis/client-essentials";
import React from "react";
import { UseMutableQueryResult } from "../../../../hooks/use-mutatable-query";
import { HandymanGetPreparationFormOutput } from "../../../../_model/api-clients/preparation-form/preparation-form-api-client-types";

export enum WorkOrderTabs {
  overview = "Översikt",
  handle = "Hantera",
  files = "Filer",
}

export enum WorkOrderControlModule {
  home,
  checkInOut,
  action,
  lineItems,
  checklist,
  requiredImages,
  externalReference,
  //materialReview,
}

export type OverviewNavigation = {
  tab: WorkOrderTabs.overview;
  module?: WorkOrderControlModule;
};
export type HandleNavigation = {
  tab: WorkOrderTabs.handle;
  module: WorkOrderControlModule;
};
export type FilesNavigation = {
  tab: WorkOrderTabs.files;
  module?: WorkOrderControlModule;
};

export type WorkOrderView =
  | OverviewNavigation
  | HandleNavigation
  | FilesNavigation;

export type HandymanWorkOrderRouteContextType = {
  orderRes: UseMutableQueryResult<WorkOrder.HandymanWithPermissionsType>;
  order: WorkOrder.HandymanWithPermissionsType;
  checklistRes: UseMutableQueryResult<Checklist.Type | undefined>;
  currentView: WorkOrderView;
  preparationFormRes: UseQueryWrapperResult<HandymanGetPreparationFormOutput>;
  navigateTo: (view: WorkOrderView) => void;
  enableMaterialReview: boolean;
  enableFailedWorkOrderReporting: boolean;
  enableUpdatedChecklist: boolean;
};
const HandymanWorkOrderRouteContext =
  React.createContext<HandymanWorkOrderRouteContextType>({} as never);

export default HandymanWorkOrderRouteContext;
